import axios from "axios";
import { router } from "./router";
import { store } from "./store/index";
import { Message } from "element-ui";

let apiBaseUrl = process.env.VUE_APP_BASE_URL;

let apiObject = axios.create({
  baseURL: apiBaseUrl,
});

apiObject.interceptors.request.use(
  (config) => {
    store.commit("setNumberOfRequests", store.state.numberOfRequestLoading + 1);
    if (store.state.numberOfRequestLoading > 0)
      store.commit("setLoadingState", true);
    return config;
  },
  (error) => {
    store.commit("setNumberOfRequests", store.state.numberOfRequestLoading - 1);
    if (store.state.numberOfRequestLoading < 1)
      store.commit("setLoadingState", false);
    console.log(error);
    return Promise.reject(error);
  }
);

apiObject.interceptors.response.use(
  async (response) => {
    store.commit("setNumberOfRequests", store.state.numberOfRequestLoading - 1);

    if (store.state.numberOfRequestLoading < 1)
      store.commit("setLoadingState", false);

    return response;
  },
  async (error) => {
    store.commit("setNumberOfRequests", store.state.numberOfRequestLoading - 1);
    if (store.state.numberOfRequestLoading < 1)
      store.commit("setLoadingState", false);
    if (error.toJSON().message === "Network Error")
      Message({ message: "No internet connection", type: "error" });

    let errorStatusCode;
    if (error && error.response && error.response.status) {
      errorStatusCode = error.response.status;
    } else {
      errorStatusCode = -1;
    }
    if (
      errorStatusCode &&
      errorStatusCode == 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      let refreshTokenResponse;
      if (!store.getters.getIsRefreshingFlag) {
        try {
          store.commit("setIsRefreshingFlag", true);
          store.commit("setLoadingState", true);
          refreshTokenResponse = await handleRefreshTokenUpdate();
        } catch (authError) {
          // refreshing has failed, but report the original error, i.e. 401
          store.commit("setIsRefreshingFlag", false);
          console.log("authError :>> ", authError);
          return Promise.reject(error);
        }
        error.config.__isRetryRequest = true;
        error.config.headers.Authorization = `Bearer ${refreshTokenResponse}`;

        // Check for token expiration or unauthorized access
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userProfile");
          sessionStorage.clear();
          // Redirect to login page
          router.push("/login");
        }
        return apiObject(error.config);
      }
    }

    return Promise.reject(error);
  }
);
async function handleRefreshTokenUpdate() {
  const refreshToken = localStorage.getItem("refreshToken");
  let userToken = localStorage.getItem("userToken");
  const userDeviceData = localStorage.getItem("userAgentData");

  const refreshTokenResponse = await axios
    .post(
      `${apiBaseUrl}auth/refresh_token`,
      {
        refreshToken: refreshToken,
        Device: userDeviceData,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          Device: userDeviceData,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
    .finally(() => {
      store.commit("setIsRefreshingFlag", false);
    });

  //Success Case
  if (
    refreshTokenResponse &&
    refreshTokenResponse.status &&
    refreshTokenResponse.status == 200
  ) {
    if (
      refreshTokenResponse &&
      refreshTokenResponse.data &&
      refreshTokenResponse.data.auth
    ) {
      let { token, refreshToken } = refreshTokenResponse.data.auth;
      localStorage.setItem("userToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      router.go();
      return token;
    }
  }

  //Failure Case
  if (
    refreshTokenResponse &&
    refreshTokenResponse.response &&
    refreshTokenResponse.response.status &&
    refreshTokenResponse.response.status == 401
  ) {
    console.log("singout data1 :>> ", userDeviceData);
    console.log("singout data2:>> ", {
      Authorization: `Bearer ${userToken}`,
      Device: userDeviceData,
    });
    const signoutResponse = await axios
      .post(
        `${apiBaseUrl}auth/signout`,
        {
          device: userDeviceData,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Device: userDeviceData,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("err", err);
        return err;
      });
    if (
      signoutResponse &&
      signoutResponse.status &&
      signoutResponse.status == 200
    ) {
      // localStorage.clear();
      localStorage.removeItem("userToken");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("userData");
      localStorage.removeItem("refreshToken");
      sessionStorage.clear();
      router.push("/login");
    } else {
      if (
        signoutResponse &&
        signoutResponse.response &&
        signoutResponse.response.status &&
        signoutResponse.response.status == 401
      ) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userProfile");
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        sessionStorage.clear();
        router.push("/login");
      }
    }
  }
  return false;
}

export const $http = apiObject;
