import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getAllReceipts(page = 1, filters, orderby) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters.areaId && filters.areaId.length == 0) {
    delete filters.areaId;
  }
  return $http.get("/admin/receipts", {
    params: {
      page,
      filter: filters,
      // status:"pending",
      orderby: orderby,
    },
    headers: authHeader(),
  });
}
function updateReceiptData(receiptId, data) {
  return $http.patch(`/admin/receipts/${receiptId}`, data, {
    headers: authHeader(),
  });
}
function addNoteToReceipt(receiptId, data) {
  return $http.patch(`/admin/receipts/${receiptId}/receiptWhsNotes`, data, {
    headers: authHeader(),
  });
}
function bulkUpdateReceipts(receiptsData) {
  return $http.put(
    `/admin/receipts/bulk`,
    {
      receipts: receiptsData,
    },
    {
      headers: authHeader(),
    }
  );
}
function exportReceiptsSheet(filters) {
  let filtersObject = JSON.parse(JSON.stringify(filters));
  let objectKeys = Object.keys(filtersObject);
  objectKeys.forEach((singleKey) => {
    let singleValue = filtersObject[singleKey];
    if (singleValue && singleValue.length == 0) {
      delete filtersObject[singleKey];
    }
  });

  return $http.get(`/admin/receipts/audit-export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: {
      filter: filtersObject,
    },
  });
}

export const receipts = {
  getAllReceipts,
  updateReceiptData,
  addNoteToReceipt,
  bulkUpdateReceipts,
  exportReceiptsSheet,
};
