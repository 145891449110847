function flattenOrderDistributers(orderDistributers) {
  let output = {};

  function constructProductPriceMap(products) {
    let output = {};
    products.forEach((p) => {
      output[p.productId] = p.cashPrice;
    });

    return output;
  }

  orderDistributers.forEach((distributer) => {
    output[distributer.distributerId] = {
      id: distributer.distributerId,
      name: distributer.name,
      minOrder: distributer.minOrder,
      products: distributer.products,
      areas: distributer.areas,
      blacklist: distributer.blacklist,
      original: distributer.original,
      receipt: distributer.receipt,
      productsPriceMap: constructProductPriceMap(distributer.products),
    };
  });

  return output;
}

export const manualOrderHandling = {
  flattenOrderDistributers,
};
