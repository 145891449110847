import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function createSection(data) {
  return $http.post("/admin/sections", data, {
    headers: authHeader(),
  });
}
function getSection(key) {
  return $http.get(`/admin/sections`, {
    params: {
      filter: { key: key },
    },
    headers: authHeader(),
  });
}
function getProviderSection(providerId) {
  return $http.get(`/admin/sections`, {
    params: {
      filter: { lookups: true, providerId: providerId },
    },
    headers: authHeader(),
  });
}

function getSectionsProviders(filtersObject) {
  return $http.get(`/admin/sections/providers`, {
    params: {
      ...filtersObject,
    },
    headers: authHeader(),
  });
}

function updateSection(key, data) {
  return $http.patch(`/admin/sections/${key}`, data, {
    headers: authHeader(),
  });
}

export const sectionsService = {
  createSection,
  getSection,
  updateSection,
  getProviderSection,
  getSectionsProviders,
};
