import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getRegions() {
  return $http.get("/admin/lookup/region", {
    headers: authHeader(),
  });
}

function getRegionsByEntity(entityId, regionId) {
  if (regionId) {
    return $http.get(`/admin/regions/industry/${entityId}`, {
      headers: authHeader(),
      params: {
        parent: regionId,
      },
    });
  }
  return $http.get(`/admin/regions/industry/${entityId}`, {
    headers: authHeader(),
  });
}

function getRegionsCsv() {
  return $http.get("/admin/regions?format=csv", {
    headers: authHeader(),
  });
}
function createRegion(data) {
  return $http.post(
    "/admin/regions",
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function editRegion({ id, data: { region, config } }) {
  return $http.patch(
    `/admin/regions/${id}`,
    { region, config },
    {
      headers: authHeader(),
    }
  );
}

function getareas({ filter, page = 1, format }) {
  return $http.get("/admin/areas", {
    headers: authHeader(),
    params: { filter, format, page },
  });
}
function createArea(data) {
  return $http.post(
    "/admin/areas",
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function editArea({ id, data: { name, regionId, minPerOrder } }) {
  return $http.patch(
    `/admin/areas/${id}`,
    { name, regionId, minPerOrder },
    {
      headers: authHeader(),
    }
  );
}
function deleteArea(id) {
  return $http.delete(`/admin/areas/${id}`, {
    headers: authHeader(),
  });
}

function getAreasLookup({ filter }) {
  return $http.get("/admin/area", {
    headers: authHeader(),
    params: { filter },
  });
}
export const regionsService = {
  createRegion,
  getRegions,
  getRegionsCsv,
  editRegion,
  getareas,
  createArea,
  editArea,
  deleteArea,
  getAreasLookup,
  getRegionsByEntity,
};
