// import obj from "@/i18n.js"
import initialLocaleDataEn from "@/locales/en.json"
import initialLocaleDataAr from "@/locales/ar.json"
import enLocale from 'element-ui/lib/locale/lang/en'
import arLocale from 'element-ui/lib/locale/lang/ar'

export default {
    namespaced: true,
    state: {
        messages: {
            'en': { ...initialLocaleDataEn, ...enLocale },
            'ar': { ...initialLocaleDataAr, ...arLocale }
        }
    },
    getters: {
        getInterMessages(state) {
            return state.messages
        }
    },
    mutations: {
        setInterMessages(state, data) {
            state.messages = data;
        }
    },
    actions: {
        async fetchAndSetNewLocale(context, payload) {
            return new Promise(function (resolve) {
                setTimeout(() => {
                    let currentMessages = context.getters.getInterMessages
                    let newSingleLocale = payload.value
                    currentMessages[payload.key] = newSingleLocale
                    context.commit('setInterMessages', currentMessages)
                    return resolve(newSingleLocale)
                }, 1000);
            });
        }
    },

}
