<template>
  <div id="fatura-dashboard">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const defaultLayout = "default";

import { mapActions, mapGetters } from "vuex";
import { utils } from "./utils";
export default {
  data() {
    return {};
  },
  async created() {
    if (this.currentUser) {
      //////// Lookups Fetch Logic
      let lookups = localStorage.getItem("storedLookups");
      if (!lookups) {
        try {
          await this.fetchLookups();
        } catch (e) {
          console.log(e);
        }
      } else {
        if (lookups) {
          lookups = JSON.parse(lookups);
          let currentTime = new Date().getTime();
          //900000
          if (lookups.timestamp && currentTime - lookups.timestamp > 900000) {
            await this.fetchLookups();
          } else {
            this.$store.commit("setLookups", lookups);
          }
        }
      }
    }
    let tempFilters = sessionStorage.getItem("filter");
    let filters = tempFilters ? JSON.parse(tempFilters) : null;
    if (!filters) {
      let newFilters = {
        providers: { type: undefined, activity: ["provides"] },
        products: {},
        ads: {},
        providerArea: {},
        receipts: {},
      };
      sessionStorage.setItem("filter", JSON.stringify({ ...newFilters }));
    }
  },
  async mounted() {
    this.initializeGoogleApi();
    utils.setLocaleDirection(this.getLanguageDirection);
    this.$store.commit("setIsRTL", this.getLanguageDirection == "rtl");
  },
  computed: {
    ...mapGetters({
      currentUser: "Authentication/currentUser",
      currentLookupsObject: "getAllLookups",
    }),
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
    getLanguageDirection() {
      return localStorage.getItem("localeDirection") || "ltr";
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    ...mapActions(["fetchLookups"]),
    initializeGoogleApi() {
      let googleIdentifierScript = document.createElement("script");
      googleIdentifierScript.src = "https://accounts.google.com/gsi/client";
      document.head.appendChild(googleIdentifierScript);
    },
  },
  watch: {
    isLoading(newCount) {
      if (newCount) {
        this.$loading();
        return;
      }
      this.$loading().close();
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  display: block;
}
</style>
