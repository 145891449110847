import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getAllTransactions(lastId, filters, orderby) {
  const { filtersCopy, sortByValue } = processFilters(filters);
  let requestParams = {
    filter: { ...filtersCopy, lastId },
    orderby: orderby,
  };
  if (sortByValue) {
    requestParams = { ...requestParams, sortby: sortByValue };
  }
  const results = $http
    .get("/admin/transaction", {
      params: requestParams,
      headers: authHeader(),
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        transactionsList: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
  return results;
}

function getTransactionDetails(transactionId) {
  const results = $http
    .get(`/admin/transaction/${transactionId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return {
        transaction: { ...res.data.data.transaction },
      };
    });
  return results;
}

function getTransactionDistributers(transactionId) {
  const results = $http
    .get(`/admin/transaction/${transactionId}/distributers`, {
      headers: authHeader(),
    })
    .then((res) => {
      return {
        transactionDistributes: res.data.data,
      };
    });
  return results;
}

function saveTransactionReceipts(transactionId, receipts) {
  const results = $http
    .post(
      `/admin/transaction/${transactionId}/receipts`,
      {
        receipts,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return {
        transactionDistributes: res.data.data,
      };
    });
  return results;
}

function retrivePrintInfo(receiptId) {
  const results = $http
    .get(`/admin/receipts/${receiptId}/print`, {
      headers: authHeader(),
    })
    .then((res) => {
      return {
        receiptPrintDetails: res.data,
      };
    });
  return results;
}

function changeTransactionStatus(transactionId, state) {
  const results = $http
    .put(
      `/admin/transaction/${transactionId}`,
      {
        state,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return {
        transaction: res.data,
      };
    })
    .catch((error) => {
      throw error.response;
    });
  return results;
}
function updateReceiptActualAmount(receiptID, receiptObj) {
  const results = $http.patch(
    `/admin/receipts/${receiptID}/actualAmount`,
    {
      ...receiptObj,
    },
    {
      headers: authHeader(),
    }
  );

  return results;
}

function updateInternalNote(userID, note) {
  const results = $http.patch(
    `/admin/user/${userID}`,
    {
      internalNote: note,
    },
    {
      headers: authHeader(),
    }
  );

  return results;
}

function exportTransactionsSheet(currentFilters) {
  const { filtersCopy, sortByValue } = processFilters(currentFilters);
  let requestParams = {
    filter: { ...filtersCopy },
  };
  if (sortByValue) {
    requestParams = { ...requestParams, sortby: sortByValue };
  }
  const results = $http.get(`/admin/transaction/export`, {
    params: requestParams,
    headers: authHeader(),
    responseType: "arraybuffer",
  });
  return results;
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters["state"] == "All") {
    filters["state"] = undefined;
  }
  if (filters["order_number"]) {
    filters["order_number"] = `T-${filters["order_number"]}`;
  }
  if (filters["has_promocode"]) {
    let valueToBeSent = filters["has_promocode"] === "Yes" ? true : false;
    filters["has_promocode"] = valueToBeSent;
  }
  if (filters["pendingOperationRequest"]) {
    let valueToBeSent =
      filters["pendingOperationRequest"] === "Yes" ? true : false;
    filters["pendingOperationRequest"] = valueToBeSent;
  }
  if (filters["isAutoAssigned"]) {
    let currentValue = filters["isAutoAssigned"];
    if (currentValue === "all") {
      delete filters.isAutoAssigned;
    }
    if (currentValue === "auto-assigned") {
      filters["isAutoAssigned"] = true;
    }
    if (currentValue === "non auto-assigned") {
      filters["isAutoAssigned"] = false;
    }
  }
  if (filters["fatura"]) {
    let valueToBeSent = filters["fatura"] === "Yes" ? 1 : 0;
    filters["fatura"] = valueToBeSent;
  }
  if (filters["tNumberInterval"]) {
    const intervalObject = filters["tNumberInterval"];
    if (intervalObject.from) filters.order_number_from = intervalObject.from;
    if (intervalObject.to) filters.order_number_to = intervalObject.to;
    delete filters.tNumberInterval;
  }
  if (filters["providers"]) {
    if (filters["providers"].length == 0) {
      delete filters.providers;
    }
  }
  if (filters["regions"]) {
    if (filters["regions"].length == 0) {
      delete filters.regions;
    }
  }
  if (filters["receiptStatus"]) {
    if (filters["receiptStatus"].length == 0) {
      delete filters.receiptStatus;
    }
  }
  if (filters["provider"]) {
    if (filters["provider"].length == 0) {
      delete filters.provider;
    }
  }
  if (filters["state"]) {
    if (filters["state"].length == 0) {
      delete filters.state;
    }
  }
  if (filters["internal_note"]) {
    let currentTextValue = filters["internal_note"];
    if (currentTextValue === "TRX With Notes") {
      filters["internal_note"] = true;
    }
    if (currentTextValue === "TRX Without Notes") {
      filters["internal_note"] = false;
    }
  }
  if (filters["hasTargets"]) {
    if (filters["hasTargets"] == "Yes") {
      filters["hasTargets"] = true;
    } else {
      filters["hasTargets"] = false;
    }
  }
  if (filters["isFirstInteraction"]) {
    if (filters["isFirstInteraction"] == "Yes") {
      filters["isFirstInteraction"] = true;
    } else {
      filters["isFirstInteraction"] = false;
    }
  }

  let filtersCopy = filters;

  let sortByValue = null;
  if (filtersCopy["sort"]) {
    if (typeof filtersCopy["sort"] === "string") {
      sortByValue = [filtersCopy["sort"]];
    } else {
      sortByValue = filtersCopy["sort"];
    }
    delete filtersCopy["sort"];
  }
  return { filtersCopy, sortByValue };
}

function stopTransactionAutoAssignment(transactionId, reason) {
  const results = $http.patch(
    `/admin/transaction/${transactionId}/cancelAutoAssign`,
    {
      cancellationReason: reason,
    },
    {
      headers: authHeader(),
    }
  );
  return results;
}

function newSaveTransactionReceipts(transactionId, requestBody) {
  const results = $http.post(
    `/admin/transaction/${transactionId}/receipts`,
    requestBody,
    {
      headers: authHeader(),
    }
  );
  return results;
}

function requestPendingOperation(transactionId, requestBody) {
  const results = $http.patch(
    `/admin/transaction/${transactionId}/request-pending-operation `,
    requestBody,
    {
      headers: authHeader(),
    }
  );
  return results;
}

function newChangeTransactionStatus(transactionId, state) {
  const results = $http.put(
    `/admin/transaction/${transactionId}`,
    {
      state,
    },
    {
      headers: authHeader(),
    }
  );
  return results;
}
function updateTransaction(trxId, updateBody) {
  const results = $http.put(`/admin/transaction/${trxId}`, updateBody, {
    headers: authHeader(),
  });

  return results;
}
function getTransactionNextStatus({
  currentStatus,
  status_type = "transaction",
}) {
  const results = $http.get(`/admin/statuses-directions`, {
    params: {
      previous_status: currentStatus,
      status_type,
    },
    headers: authHeader(),
  });
  return results;
}
function getTransactionPendingOpsReasons() {
  const results = $http.get(`/admin/request-pending-operation-reasons`, {
    headers: authHeader(),
  });
  return results;
}
function updatedChangeTransactionStatus(transactionId, requestBody) {
  const results = $http.put(
    `/admin/transaction/${transactionId}`,
    requestBody,
    {
      headers: authHeader(),
    }
  );
  return results;
}
function getTransactionStatusReasons(filters) {
  const results = $http.get(`/admin/lookup/statuses-reasons`, {
    headers: authHeader(),
    params: filters,
  });
  return results;
}

function getTransactionValidateUnbelieverReasons() {
  const results = $http.get(`/admin/receipts/validate-undeliver-reason`, {
    headers: authHeader(),
  });

  return results;
}
function updatedTransactionValidateUnbelieverReasons(
  transactionId,
  requestBody
) {
  const results = $http.patch(
    `/admin/receipts/${transactionId}/validate-undeliver-reason`,
    requestBody,
    {
      headers: authHeader(),
    }
  );
  return results;
}

function getReceiptsRerouteOptions(receiptId) {
  const results = $http.get(`/admin/receipts/${receiptId}/reroute-options`, {
    headers: authHeader(),
  });
  return results;
}
function submitRerouteRequest(receiptId, data) {
  const results = $http.post(
    `/admin/receipts/${receiptId}/reroute`,
    {
      ...data,
    },
    {
      headers: authHeader(),
    }
  );
  return results;
}
export const transactions = {
  getAllTransactions,
  getTransactionDetails,
  getTransactionDistributers,
  saveTransactionReceipts,
  retrivePrintInfo,
  changeTransactionStatus,
  updateReceiptActualAmount,
  updateInternalNote,
  exportTransactionsSheet,
  stopTransactionAutoAssignment,
  newSaveTransactionReceipts,
  newChangeTransactionStatus,
  updateTransaction,
  getTransactionNextStatus,
  getTransactionPendingOpsReasons,
  updatedChangeTransactionStatus,
  getTransactionStatusReasons,
  getTransactionValidateUnbelieverReasons,
  updatedTransactionValidateUnbelieverReasons,
  getReceiptsRerouteOptions,
  submitRerouteRequest,
  requestPendingOperation,
};
