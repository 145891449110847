import { Message } from "element-ui";
import i18n from "../i18n";

function downloadCSV(csvStr, name) {
  var hiddenElement = document.createElement("a");
  var encodedData = encodeURIComponent(csvStr);
  hiddenElement.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodedData}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}
function downloadBlob(file, name, fileType) {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(file);
  hiddenElement.target = "_blank";
  hiddenElement.download = `${name}.${fileType}`;
  hiddenElement.click();
}
function decodeArrayBuffer(data) {
  let decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
  let decodedData = JSON.parse(decodedString);
  return decodedData;
}

function copyText(text) {
  var hiddenElement = document.createElement("textarea");
  hiddenElement.value = text;
  hiddenElement.setAttribute("readonly", "");
  hiddenElement.style.position = "absolute";
  hiddenElement.style.left = "-9999px";
  document.body.appendChild(hiddenElement);

  hiddenElement.select();

  document.execCommand("copy");
  document.body.removeChild(hiddenElement);
}

function arrayEliminateDuplicatesUsingProperty(array, property) {
  return Object.values(
    array.reduce((acc, cur) => Object.assign(acc, { [cur[property]]: cur }), {})
  );
}
function setLocaleDirection(direction) {
  const bodyElemnt = document.getElementsByTagName("body")[0];
  bodyElemnt.style.direction = direction;
  bodyElemnt.setAttribute("dir", `${direction}`);
}
function getDeviceData() {
  var nAgt = window.navigator.userAgent;
  var browserName = window.navigator.appName;
  var fullVersion = "" + parseFloat(window.navigator.appVersion);
  var majorVersion = parseInt(window.navigator.appVersion, 10);
  var nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = window.navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(window.navigator.appVersion);
    majorVersion = parseInt(window.navigator.appVersion, 10);
  }
  fullVersion = new Date().getTime();
  let OSName = "Unknown OS";
  if (window.navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (window.navigator.appVersion.indexOf("ac") != -1) OSName = "MacOS";
  if (window.navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (window.navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  let outputString =
    "FaturaPortal/1.0.0 " +
    "(" +
    browserName +
    ") " +
    OSName +
    "/" +
    fullVersion;
  // +' '
  // +'Major version = '+majorVersion+' '
  // +'window.navigator.appName = '+window.navigator.appName+' '
  // +'window.navigator.userAgent = '+window.navigator.userAgent+' '
  return outputString;
}

function getMediaTypeFromURL(url) {
  const images = ["jpeg", "webp"];
  const videos = ["mp4"];
  const urlType = url.split(".")[1];
  if (images.some((type) => type === urlType)) return "image";
  if (videos.some((type) => type === urlType)) return "video";
}
function popupMessageWrapper(message = "", type = "success", duration = 1000) {
  Message({ message, type, duration });
}

function errorMessageExtractor(errorObject) {
  const errorMessageExistsError =
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.error;
  const errorMessageExistsMessage =
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.message;
  let finalErrorMessage = i18n.t("GlobalErrorMessage");
  if (errorMessageExistsError) {
    finalErrorMessage = errorObject.response.data.error;
  }
  if (errorMessageExistsMessage) {
    finalErrorMessage = errorObject.response.data.message;
  }
  return finalErrorMessage;
}
export const utils = {
  downloadCSV,
  copyText,
  arrayEliminateDuplicatesUsingProperty,
  setLocaleDirection,
  downloadBlob,
  decodeArrayBuffer,
  getDeviceData,
  getMediaTypeFromURL,
  popupMessageWrapper,
  errorMessageExtractor,
};
