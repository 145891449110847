import { userService } from "@/services";
import { router } from "@/router";
import { utils } from "@/utils";
const user = JSON.parse(localStorage.getItem("userProfile"));

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { phone, password }) {
      try {
        const userAgentData = utils.getDeviceData();
        localStorage.setItem("userAgentData", userAgentData);
      } catch (error) {
        console.log("errorUserAgentCreation :>> ", error);
        localStorage.setItem("userAgentData", "deviceAgentDataError");
      }
      return new Promise((resolve, reject) => {
        userService.login(phone, password).then(
          (user) => {
            userService.getUserProfile().then((res) => {
              localStorage.setItem(
                "userProfile",
                JSON.stringify(res.data.user)
              );
              commit("loginSuccess", { user, userProfile: res.data.user });

              dispatch("fetchLookups", null, { root: true });
              resolve(res);
              router.push("/home");
            });
          },
          (error) => {
            commit("loginFailure", error);
            reject(error);
            dispatch("Alert/error", error, { root: true });
          }
        );
      });
    },
    loginSocial({ dispatch, commit }, { credential }) {
      return new Promise((resolve, reject) => {
        userService.loginSocial(credential).then(
          (user) => {
            userService.getUserProfile().then((res) => {
              localStorage.setItem(
                "userProfile",
                JSON.stringify(res.data.user)
              );
              commit("loginSuccess", { user, userProfile: res.data.user });

              dispatch("fetchLookups", null, { root: true });
              resolve(res);
              router.push("/home");
            });
          },
          (error) => {
            commit("loginFailure", error);
            reject(error);
            dispatch("Alert/error", error, { root: true });
          }
        );
      });
    },

    logout({ commit }) {
      userService.logout();
      commit("logout");
      router.push("/login");
    },
  },
  getters: {
    currentUser: function (state) {
      return state.user;
    },
  },
  mutations: {
    loginSuccess(state, { user, userProfile }) {
      state.status = { loggedIn: true };
      state.userToken = user.token;
      state.user = userProfile;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};
