<template>
    <el-container>
        <slot />
    </el-container>
</template>

<script>
export default {

};
</script>
