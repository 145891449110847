import { $http } from "@/api";
import { authHeader } from "@/authHeader";
import moment from "moment";

function fetchPromocodes(page = 1, filters) {
  const { filtersCopy } = processFilters(filters);
  const requestParams = {
    filter: { ...filtersCopy },
    page,
  };
  return $http
    .get(`/admin/promocode`, {
      params: requestParams,
      headers: authHeader(),
    })
    .then((res) => {
      let fetchedPromocodes = res.data.promocodes;
      fetchedPromocodes.forEach((singlePromo) => {
        singlePromo.isActive = singlePromo.is_active === 1 ? true : false;
      });
      return {
        promocodesList: fetchedPromocodes,
        pagination: {
          totalPages: res.data.pages,
          totalResultsCount: res.data.count,
          nextPage: res.data.next,
        },
      };
    });
}

function updatePromocode(updateData) {
  if (updateData.hasOwnProperty("isActive")) {
    if (updateData.isActive === true) {
      updateData.is_active = 1;
    } else {
      updateData.is_active = 0;
    }
  }
  delete updateData.isActive;
  return $http.patch(`/admin/promocode/${updateData.id}`, updateData, {
    headers: authHeader(),
  });
}

function createPromocode(createData) {
  createData.is_active = createData.isActive === true ? 1 : 0;
  return $http.post(`/admin/promocode/`, createData, {
    headers: authHeader(),
  });
  // .then((res) => {
  //     return res
  // })
  // .catch(err=>{
  //     return err
  // })
}

function uploadPromocodeSheetAction(promocodeId, requestAction, formData) {
  if (requestAction == "create" || requestAction == "update") {
    return $http
      .post(`/admin/promocode/import`, formData, {
        headers: authHeader(),
        params: {
          action: requestAction,
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } else if(requestAction == 'assign'|| requestAction == "append" || requestAction == 'unassign'){
    return $http
      .post(`/admin/promocode/bulk/import/assign`, formData, {
        headers: authHeader(),
        params: {
          promocodeId,
          action: requestAction,
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  else {
    formData.append("action", requestAction);
    formData.append("id", promocodeId);
    return $http
      .post(
        `/admin/promocode/assign`,
        // {
        //     id:promocodeId,
        //     action:requestAction
        // },
        formData,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    // return new Promise(function(resolve) {
    //     // the function is executed automatically when the promise is constructed
    //     setTimeout(() => resolve(
    //         {
    //            status:'success',
    //            data:{requestAction,formData}
    //         }
    //     ), 1000);
    // });
  }
}

function exportPromocodeSheet(promocodeId) {
  return $http
    .get(`/admin/promocode/export`, {
      params: { id: promocodeId },
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
  // return new Promise(function(resolve) {
  //     // the function is executed automatically when the promise is constructed
  //     setTimeout(() => resolve(
  //         {
  //            status:'success',
  //            data:{promocodeId}
  //         }
  //     ), 1000);
  // });
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters?.creationDate && !filters?.actualDate) {
    filters = {
      ...filters,
      creation_date_start : moment(filters.creationDate[0]).startOf('day').valueOf(),
      creation_date_end: moment(filters.creationDate[1]).endOf('day').valueOf(),
    };
  } 
  else if(!filters.creationDate && filters.actualDate) {
    filters = {
    ...filters,
    start_date: moment(filters.actualDate[0]).startOf('day').valueOf(),
    end_date: moment(filters.actualDate[1]).endOf('day').valueOf(),
    }
  } 
  else if(filters.creationDate && filters.actualDate){
    filters = {
    ...filters,
    creation_date_start : moment(filters.creationDate[0]).startOf('day').valueOf(),
    creation_date_end: moment(filters.creationDate[1]).endOf('day').valueOf(),
    start_date: moment(filters.actualDate[0]).startOf('day').valueOf(),
    end_date: moment(filters.actualDate[1]).endOf('day').valueOf(),
    }
  }
  else {
    filters = {
      ...filters,
      start_date: undefined,
      end_date: undefined,
      creation_date_start: undefined,
      creation_date_end: undefined,
    };
    delete filters?.creationDate;
    delete filters?.actualDate;
  }

  if (filters.promocodeId) {
    filters.promocodeId = [filters.promocodeId];
  }
  if (filters["is_active"]) {
    let valueToBeSent = filters["is_active"] === "Active" ? true : false;
    filters["is_active"] = valueToBeSent;
  }
  delete filters?.creationDate;
  delete filters?.actualDate;
  let filtersCopy = filters;

  return { filtersCopy };
}
function exportPromocodesSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  return $http.get(`/admin/promocode/bulk/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: {
      filter: { ...filtersCopy },
      action,
    },
  });
}
function exportPromocodesUsersAssignmentSheet(action, filters) {
  const { filtersCopy } = processFilters(filters);
  return $http.get(`/admin/promocode/bulk/export/assign`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: {
      filter: { ...filtersCopy },
      action,
    },
  });
}
function exportPromocodeSingleAssignation(action) {
  // const { filtersCopy } = processFilters(filters);
  return $http.get(`/admin/promocode/bulk/export/assign`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: {
      action,
    },
  });
}

export const promocodesService = {
  fetchPromocodes,
  updatePromocode,
  createPromocode,
  uploadPromocodeSheetAction,
  exportPromocodeSheet,
  exportPromocodesSheet,
  exportPromocodesUsersAssignmentSheet,
  exportPromocodeSingleAssignation,
};
