import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import ElementUI from "element-ui";
import VueVideoPlayer from "vue-video-player";
import i18n from "./i18n";
import { $http } from "./api";
import EnlargeableImage from "@diracleo/vue-enlargeable-image";
import globalFunctions from "./utils/extendedFunctions";

import "./assets/fature-elements-theme/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "element-ui/lib/theme-chalk/descriptions-item.css";
import "element-ui/lib/theme-chalk/descriptions.css";
import "./styles/main.scss";

import DefaultLayout from "./layouts/Dashboard.vue";
import noSidebarLayout from "./layouts/noSidebar.vue";

Vue.component("default-layout", DefaultLayout);
Vue.component("no-sidebar-layout", noSidebarLayout);

Vue.config.productionTip = false;

// configure language
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(require("vue-moment"));
Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);
// making global axios
Vue.prototype.$http = $http;

// global functions setup
Vue.prototype.$globalFunctions = globalFunctions;

Vue.use(EnlargeableImage);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
