import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function sendNotification({
  message,
  areas,
  userGroupFilters,
  title,
  users,
  userGroup,
}) {
  const requestData = {
    title,
    message,
  };

  if (areas && areas.length) {
    requestData.areas = areas;
  }
  if (Object.keys(userGroupFilters).length) {
    requestData.userGroupFilters = {};
    for (const key in userGroupFilters) {
      if (userGroupFilters[key] && userGroupFilters[key].length)
        requestData.userGroupFilters[key] = userGroupFilters[key];
    }
  }

  if (users && users.length) {
    requestData.users = users;
  }
  if (userGroup && userGroup.length) {
    requestData.userGroup = userGroup;
  }

  return $http.post("/admin/notification/send", requestData, {
    headers: authHeader(),
  });
}
function getAdminNotifications(page = 1, read) {
  let readVal = read == "All" ? undefined : read;
  return $http.get("/admin/notification", {
    params: {
      page,
      read: readVal,
    },
    headers: authHeader(),
  });
}
function readNotifications(body) {
  return $http.patch(
    "/admin/notification",
    { ...body },
    {
      headers: authHeader(),
    }
  );
}
function getConfig(param) {
  return $http.get(`/config/${param}`, { headers: authHeader() });
}

function sendBulkNotification(formData) {
  return $http.post("/admin/notification/bulk-send", formData, {
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
}

function ForceResetPassword(formData) {
  return $http.patch("/admin/user/bulk-reset-password", formData, {
    headers: {
      ...authHeader(),
    },
  });
}
function generateOTP(requestBody) {
  return $http.post("/admin/user/otp", requestBody, {
    headers: {
      ...authHeader(),
    },
  });
}

function getOTPByPhoneNumber(phoneNumber) {
  return $http.get(`/admin/retailer/${phoneNumber}/otp`, {
    headers: {
      ...authHeader(),
    },
  });
}

export const adminActions = {
  sendNotification,
  getConfig,
  getAdminNotifications,
  readNotifications,
  sendBulkNotification,
  ForceResetPassword,
  generateOTP,
  getOTPByPhoneNumber
};
