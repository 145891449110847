import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getRetailers(page = 1, filters = {}) {
  return $http
    .get("/admin/retailer", {
      headers: authHeader(),
      params: { page: page, filter: processFilters(filters) },
    })
    .then((res) => {
      const { result, count } = res.data.data;
      return {
        retailersList: result,
        pagination: { totalResultsCount: count },
      };
    });
}
function getRetailersCsv(filters = {}) {
  return $http.get("/admin/retailers/export", {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { filter: processFilters(filters), action: "update" },
  });
}

function getRetailerDetails(retailerId) {
  return $http
    .get(`/admin/retailer/${retailerId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data.data;
    });
}

function getChannelTypes(industryId) {
  return $http
    .get(`/business-types/industry/${industryId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      console.log(res.data.data);
      return res.data.data;
    });
}
function getSubAreas(areaId) {
  return $http
    .get(`admin/areas/districts/${areaId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      console.log(res.data.data);
      return res.data.data;
    });
}
function getBlacklistUsers(retailerId) {
  return $http
    .get(`/admin/retailer/${retailerId}/blacklist`, {
      headers: authHeader(),
    })
    .then((res) => {
      const { results, count } = res.data.data;
      return {
        blacklistUsers: results,
        pagination: { totalResultsCount: count },
      };
    });
}
function getRetailersUsers(retailerId) {
  return $http
    .get(`/admin/retailer/${retailerId}/users-profile`, {
      headers: authHeader(),
    })
    .then((res) => {
      const { result } = res.data.data;
      return {
        retailerUsers: result,
      };
    });
}
function updateRetailer(entityId, obj) {
  return $http.put(`/admin/retailer/${entityId}`, obj, {
    headers: authHeader(),
  });
}
function bulkUpdateRetailers(retailers) {
  return $http.put(
    `/admin/retailer/bulk`,
    { retailers },
    {
      headers: authHeader(),
    }
  );
}
function updateRetailersUsers(retailerId, userId, data) {
  return $http.put(
    `/admin/retailer/${retailerId}/user-profile/${userId}`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
}

function getRetailersDeactivatedUsers(retailerId) {
  return $http.get(`/admin/retailer/${retailerId}/deactivated-users`, {
    headers: authHeader(),
  });
}

function importRetailerOfferSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/retailers/import/offers-percentage?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}

function importCappingPercentageSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/retailers/import/cashback-capping-percentage?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}

function exportRetailerOfferSheet(action, filters) {
  let requestParams = {
    filters: processFilters(filters),
    action,
  };

  return $http.get(`/admin/retailers/export/offers-percentage`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function exportCappingPercentageSheet(action) {
  let requestParams = {
    action,
  };

  return $http.get(`/admin/retailers/export/cashback-capping-percentage`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function processFilters(filters) {
  if (filters["hasSecondPhone"]) {
    let valueToBeSent = filters["hasSecondPhone"] === "Yes" ? true : false;
    filters["hasSecondPhone"] = valueToBeSent;
  }
  filters = JSON.parse(JSON.stringify(filters));
  return filters;
}

function importRetailersUpdateSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/retailers/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}
export const retailersService = {
  getRetailers,
  getChannelTypes,
  getSubAreas,
  getRetailersCsv,
  getRetailerDetails,
  getBlacklistUsers,
  getRetailersUsers,
  updateRetailer,
  bulkUpdateRetailers,
  updateRetailersUsers,
  getRetailersDeactivatedUsers,
  importRetailerOfferSheet,
  importCappingPercentageSheet,
  exportRetailerOfferSheet,
  importRetailersUpdateSheet,
  exportCappingPercentageSheet,
};
