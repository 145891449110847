<template>
  <!-- <div class="c-dashboard-sidebar"> -->
  <div>
    <el-menu
      active-text-color="#354850"
      text-color="rgba(43,48,52,0.4)"
      :router="true"
      :mode="mode"
      :default-active="$route.path"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
    >
      <component
        v-for="item in navItems"
        :index="item.index"
        :route="item.route"
        :key="item.index"
        :is="item.compName"
      >
        <template slot="title">
          <span>{{ $t(item.label) }}</span>
        </template>
        <el-menu-item
          v-for="nItem in item.nestedRoutes"
          :index="nItem.index"
          :route="nItem.route"
          :key="nItem.index"
        >
          <template slot="title">
            <span>{{ $t(nItem.label) }}</span>
          </template>
        </el-menu-item>
      </component>
    </el-menu>
  </div>
</template>

<script>
import { NavItemsConfiguration } from "../configs";
export default {
  name: "sidebar",
  props: {
    mode: { default: "horizontal" },
  },
  data() {
    return {
      isCollapse: false,
      navItems: NavItemsConfiguration.getNavItems(),
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },

};
</script>

<style lang="scss">
// .c-dashboard-sidebar {
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
// }

.el-menu {
  border: none !important;
  font-weight: 900;
  .el-menu-item {
    height: 70px !important;
  }

  .el-submenu.is-active,
  .el-menu-item.is-active {
    border-bottom: 4px solid #fccd13 !important;
  }
  .el-submenu__title {
    border: none !important;
    height: 70px !important;
  }

  //   .el-submenu {
  //     .el-menu-item {
  //       min-width: 152.19px !important;
  //     }
  //   }
}
.el-menu--popup-bottom-start {
  .el-menu-item {
    height: 36px !important;
  }
  .el-menu-item.is-active {
    border: none !important;
  }
}
// .c-dashboard-sidebar__switcher {
//   margin-top: auto;
//   margin-bottom: 16px;
//   align-items: center;
//   justify-content: center;
// }
</style>
