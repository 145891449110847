import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function listAllProviders(page = 1, filters) {
  return $http
    .get(`/admin/provider`, {
      params: { page, filter: filters },
      headers: authHeader(),
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        providersList: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}
function getProvidersCsv(page = 1, filtersObj) {
  return $http.get(`/admin/provider`, {
    params: { page, ...filtersObj },
    headers: authHeader(),
  });
}

function getProviderlookup(filter) {
  return $http
    .get(`/admin/lookup/distributer`, {
      headers: authHeader(),
      params: {
        // filter: { provider: sectionType, regions: [region] },
        filter: filter,
      },
    })
    .then((res) => {
      const { result } = res.data;
      return result;
    });
}

function assginProvidersTosections(data) {
  return $http.post(
    "/admin/distributerSectionArea",
    { ...data },
    {
      headers: authHeader(),
    }
  );
}

function getProviderProfile(providerId, filters) {
  return $http
    .get(`/admin/provider/${providerId}`, {
      headers: authHeader(),
      params: filters
        ? { filter: { ...processProviderFilters(filters) } }
        : undefined,
    })
    .then((res) => {
      const {
        data: { result },
      } = res.data;
      return result;
    });
}

function createProvider(providerDetails) {
  let apiUrl = "/admin/provider";
  // if (
  //   providerDetails.provider &&
  //   typeof providerDetails.provider === "number"
  // ) {
  //   apiUrl = "/admin/provider";
  // }
  providerDetails = { ...providerDetails, meta: { logo: "0" } };
  return $http
    .post(apiUrl, providerDetails, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
}

function getProducts(filters, page = 1) {
  return $http
    .get("/admin/product", {
      headers: authHeader(),
      params: { page, filter: filters },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        products: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}

function updateProviderAreas({ providerId, filters }) {
  return $http
    .put(
      `/admin/provider/${providerId}/area`,
      {
        ...filters,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    });
}

function updateProviderPortofolio({ providerId, products }) {
  return $http.put(
    `/admin/provider/${providerId}/product`,
    {
      products,
    },
    {
      headers: authHeader(),
    }
  );
}
function assignProvidersToProduct(productID, entityProductUnits) {
  return $http.post(
    `/admin/provider/${productID}/product`,
    {
      entityProductUnits: entityProductUnits,
    },
    {
      headers: authHeader(),
    }
  );
}
function updateProviderProfile({ providerId, data }) {
  return $http
    .put(
      `/admin/provider/${providerId}/profile`,
      {
        ...data,
      },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res.data;
    });
}
function copyProviderProfile(Id, distributerId) {
  return $http.post(
    `/admin/provider/${Id}/copy-profile`,
    {
      distributerId: distributerId,
    },
    {
      headers: authHeader(),
    }
  );
}
function newAssignProvidersToSections(entityId, data) {
  return $http.put(
    `admin/provider/${entityId}/entity-section-area`,
    { data },
    {
      headers: authHeader(),
    }
  );
}
function deleteProviderSection(entityId, areaId, sectionKey) {
  return $http.delete(
    `/admin/provider/entity-section-area/${entityId}/${areaId}/${sectionKey}`,
    {
      headers: authHeader(),
    }
  );
}
function updateProviderImage(data) {
  return $http
    .post(`/admin/provider/image-upload`, data, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
}

function importProviderPortfolio(toBeSentFormData, action) {
  return $http.post(
    `/admin/entityProducts/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}
function importBulkToggleSheet(toBeSentFormData) {
  return $http.post(`/admin/provider/import/live-status`, toBeSentFormData, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}

function exportBulkToggleTemplate(action) {
  return $http.get(`/admin/provider/export/live-status`, {
    params: {
      action,
    },
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}

function exportProviderSheet(action, entityId) {
  let paramsObject = { action };
  if (entityId) {
    paramsObject.filters = {
      entityId,
    };
  }
  return $http.get(`/admin/entityProducts/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { ...paramsObject },
  });
}

function fetchBlacklistedUsers(providerId) {
  return $http.get(`/admin/entityBlacklists/${providerId}`, {
    headers: authHeader(),
  });
}

function removeProductsFromPortfolio(entityId, productIds) {
  return $http.delete(`/admin/entityProducts`, {
    headers: authHeader(),
    data: {
      entityId,
      products: productIds,
    },
  });
}
function getProviderProducts(providerId, filterParams) {
  return $http.get(`/admin/provider/${providerId}/products`, {
    params: filterParams,
    headers: authHeader(),
  });
}
function getProductsSeperateUnits(filters, page = 1) {
  const { filtersCopy } = processFilters(filters);
  return $http
    .get("/admin/productUnit", {
      headers: authHeader(),
      params: { page, filter: { ...filtersCopy } },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        products: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}

function getProviderUsers(providerId) {
  return $http
    .get(`/admin/retailer/${providerId}/users-profile`, {
      headers: authHeader(),
    })
    .then((res) => {
      const { result } = res.data.data;
      return {
        retailerUsers: result,
      };
    });
}
function getProviderArea(providerId, sections = []) {
  return $http.get(
    `/admin/provider/${providerId}/area?sectionKeys=[${sections.map(
      (section) => '"' + section + '"'
    )}]`,
    {
      headers: authHeader(),
    }
  );
}
function getProviderAreaAds(providerId) {
  return $http.get(`/admin/provider/${providerId}/area`, {
    headers: authHeader(),
  });
}
function getProviderRegion(providerId, sections = []) {
  return $http.get(
    `/admin/provider/${providerId}/region?sectionKeys=[${sections.map(
      (section) => '"' + section + '"'
    )}]`,
    {
      headers: authHeader(),
    }
  );
}
function getProviderRegionAds(providerId) {
  return $http.get(`/admin/provider/${providerId}/region`, {
    headers: authHeader(),
  });
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));

  if (filters.hasOwnProperty("name") && !filters["name"].length) {
    delete filters["name"];
  }
  if (filters.hasOwnProperty("isAreaActive")) {
    filters.isAreaActive === "Active"
      ? (filters.isAreaActive = true)
      : (filters.isAreaActive = false);
  }
  if (filters.hasOwnProperty("isAssignedToEntity")) {
    filters.isAssignedToEntity === "Assigned"
      ? (filters.isAssignedToEntity = true)
      : (filters.isAssignedToEntity = false);
  }
  if (filters.hasOwnProperty("isInStock")) {
    filters.isInStock === "In Stock"
      ? (filters.isInStock = true)
      : (filters.isInStock = false);
  }
  if (filters.hasOwnProperty("isOffer")) {
    filters.isOffer === "Yes"
      ? (filters.isOffer = true)
      : (filters.isOffer = false);
  }
  if (filters.hasOwnProperty("from") || filters.hasOwnProperty("to")) {
    filters.price = {
      from: filters.from,
      to: filters.to,
    };

    delete filters.from;
    delete filters.to;
  }
  // if (filters.hasOwnProperty("from") && !filters.hasOwnProperty("to")) {
  //   filters.price = {
  //     from: filters.from,
  //     to: undefined,
  //   };
  //   delete filters.from;
  // }
  // if (filters.hasOwnProperty("to") && !filters.hasOwnProperty("from")) {
  //   filters.price = {
  //     to: filters.to,
  //     from: undefined,
  //   };
  //   delete filters.to;
  // }
  // if (filters.hasOwnProperty("to") && filters.hasOwnProperty("from")) {
  //   filters.price = {
  //     to: filters.to,
  //     from: filters.from,
  //   };
  //   delete filters.to;
  //   delete filters.from;
  // }
  let filtersCopy = filters;
  return { filtersCopy };
}
function processProviderFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));

  if (filters.hasOwnProperty("isAreaActive")) {
    filters.isAreaActive === "Active"
      ? (filters.isAreaActive = true)
      : (filters.isAreaActive = false);
  }
  let filtersCopy = filters;
  return filtersCopy;
}

function getSectionsByEntity(entityId) {
  return $http.get(`/admin/sections/industry/${entityId}`, {
    headers: authHeader(),
  });
}

export const providersService = {
  getSectionsByEntity,
  listAllProviders,
  getProvidersCsv,
  getProducts,
  createProvider,
  updateProviderAreas,
  getProviderProfile,
  updateProviderPortofolio,
  assignProvidersToProduct,
  updateProviderProfile,
  getProviderlookup,
  copyProviderProfile,
  assginProvidersTosections,
  newAssignProvidersToSections,
  deleteProviderSection,
  updateProviderImage,
  importProviderPortfolio,
  exportProviderSheet,
  fetchBlacklistedUsers,
  removeProductsFromPortfolio,
  getProviderProducts,
  getProviderArea,
  getProviderAreaAds,
  getProviderRegion,
  getProviderRegionAds,
  getProductsSeperateUnits,
  getProviderUsers,
  exportBulkToggleTemplate,
  importBulkToggleSheet,
};
