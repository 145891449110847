import { Message } from "element-ui";
import i18n from "../i18n";

function popupMessageWrapper(message = "", type = "success", duration = 1000) {
  Message({ message, type, duration });
}
function isArrayBuffer(value) {
  return value && value.byteLength !== undefined;
}
function arraybufferToString(buf) {
  var enc = new TextDecoder("utf-8");
  return enc.decode(buf);
}

function errorMessageExtractor(errorObject) {
  if (isArrayBuffer(errorObject.response.data)) {
    errorObject.response.data = JSON.parse(
      arraybufferToString(errorObject.response.data)
    );
  }
  const errorMessageExistsError =
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.error;
  const errorMessageExistsMessage =
    errorObject.response &&
    errorObject.response.data &&
    errorObject.response.data.message;
  let finalErrorMessage = i18n.t("GlobalErrorMessage");
  if (errorMessageExistsError) {
    finalErrorMessage = errorObject.response.data.error;
  }
  if (errorMessageExistsMessage) {
    finalErrorMessage = errorObject.response.data.message;
  }
  return finalErrorMessage;
}

export default { popupMessageWrapper, errorMessageExtractor };
