export function authHeader() {
  // return authorization header with jwt token
  let userToken = localStorage.getItem("userToken");
  let userAgentData = localStorage.getItem("userAgentData");

  if (userToken) {
    return {
      Authorization: `Bearer ${userToken}`,
      Device: userAgentData,
    };
  } else {
    return {};
  }
}
