import { transactions, adminActions } from "@/services";
import { cloneDeep, find, remove } from "lodash";

// const {
//   constructTransactionPriceMap,
//   calculateTransactionAmounts,
//   calculateReceiptsAndSpecifyDistributers,
//   processTrxItems,
//   processAlreadySavedReceipts,
//   constructCancelledObject,
// } = require("../../services/trxDetailsHelpers");

import {
  constructTransactionPriceMap,
  calculateTransactionAmounts,
  calculateReceiptsAndSpecifyDistributers,
  processTrxItems,
  processAlreadySavedReceipts,
  constructCancelledObject,
  checkReceiptUpdated,
  selectBestDistibuters,
  flattenOrderDistributers,
  matchTrxItemToReceiptItem,
} from "../../services/trxDetailsHelpers";

const initialState = () => {
  return {
    transactionDetails: null,
    creditConfig: null,
    orderDistributers: null,
    transactionTotalAmount: null,
    transactionTotalOriginalAmount: null,
    transactionCreditTotalAmount: null,
    transactionCreditTotalOriginalAmount: null,
    transactionId: null,
    canPrint: true,
    productDistirubtersPricesMap: {},
    initialTransactionReceipts: [],
    selectedDistributers: null,
    transactionReceipts: null,
    transactionItems: [],
    cancelledOrderProps: {},
  };
};

const trxStore = {
  state: {
    transactionDetails: null,
    creditConfig: null,
    orderDistributers: null,
    transactionTotalAmount: null,
    transactionTotalOriginalAmount: null,
    transactionCreditTotalAmount: null,
    transactionCreditTotalOriginalAmount: null,
    transactionId: null,
    canPrint: true,
    productDistirubtersPricesMap: {},
    initialTransactionReceipts: [],
    selectedDistributers: null,
    transactionReceipts: null,
    transactionItems: [],
    cancelledOrderProps: {},
    isDistributerSelected: false
  },
  getters: {
    getTransactionDetails(state) {
      return state.transactionDetails;
    },
    getCreditConfig(state) {
      return state.creditConfig;
    },
    getOrderDistributers(state) {
      return state.orderDistributers;
    },
    getTransactionTotalAmount(state) {
      return state.transactionTotalAmount;
    },
    getTransactionCreditTotalAmount(state) {
      return state.transactionCreditTotalAmount;
    },
    getTransactionId(state) {
      return state.transactionId;
    },
    getCanPrint(state) {
      return state.canPrint;
    },
    getProductDistirubtersPricesMap(state) {
      return state.productDistirubtersPricesMap;
    },
    getInitialTransactionReceipts(state) {
      return state.initialTransactionReceipts;
    },
    getSelectedDistributers(state) {
      return state.selectedDistributers;
    },
    getTransactionReceipts(state) {
      return state.transactionReceipts;
    },
    getTransactionTotalOriginalAmount(state) {
      return state.transactionTotalOriginalAmount;
    },
    getTransactionCreditTotalOriginalAmount(state) {
      return state.transactionCreditTotalOriginalAmount;
    },
    getTransactionItems(state) {
      return state.transactionItems;
    },
    getCancelledOrderProps(state) {
      return state.cancelledOrderProps;
    },
    getIsDistributerSelected(state){
      return state.isDistributerSelected;
    }
  },
  mutations: {
    setTransactionDetails(state, data) {
      state.transactionDetails = data;
    },
    setCreditConfig(state, data) {
      state.creditConfig = data;
    },
    setOrderDistributers(state, data) {
      state.orderDistributers = data;
    },
    setTransactionTotalAmount(state, data) {
      state.transactionTotalAmount = data;
    },
    setTransactionCreditTotalAmount(state, data) {
      state.transactionCreditTotalAmount = data;
    },
    setTransactionId(state, data) {
      state.transactionId = data;
    },
    setCanPrint(state, data) {
      state.canPrint = data;
    },
    setProductDistirubtersPricesMap(state, data) {
      state.productDistirubtersPricesMap = data;
    },
    setInitialTransactionReceipts(state, data) {
      state.initialTransactionReceipts = data;
    },
    setSelectedDistributers(state, data) {
       state.selectedDistributers = data;
    },
    setTransactionReceipts(state, data) {
      state.transactionReceipts = data;
    },
    setTransactionTotalOriginalAmount(state, data) {
      state.transactionTotalOriginalAmount = data;
    },
    setTransactionCreditTotalOriginalAmount(state, data) {
      state.transactionCreditTotalOriginalAmount = data;
    },
    setTransactionItems(state, data) {
      state.transactionItems = data;
    },
    setCancelledOrderProps(state, data) {
      state.cancelledOrderProps = data;
    },
    clearTrxData(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    conditionalClearTrxData(state, deleteKeys) {
      const newState = initialState();
      deleteKeys.forEach((singleKey) => {
        delete newState[singleKey];
      });
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setIsDistributerSelected(state, value) {
       state.isDistributerSelected = value;
    }
  },
  actions: {
    async fetchTrxDetailsData(context, trxId) {
      try {
        const creditConfig = adminActions.getConfig("credit");
        const transactionDetails = transactions.getTransactionDetails(trxId);
        const transactionDistributers =
          transactions.getTransactionDistributers(trxId);
        const [creditResult, transactionResult, distributerResult] =
          await Promise.all([
            creditConfig,
            transactionDetails,
            transactionDistributers,
          ]);
        context.commit("conditionalClearTrxData", [
          "transactionId",
          "creditConfig",
        ]);
        let creditConfigData = { ...creditResult?.data };
        context.commit("setCreditConfig", creditConfigData);

        let orderDistributersData = [
          ...cloneDeep(distributerResult?.transactionDistributes),
        ];
 
        orderDistributersData = flattenOrderDistributers(orderDistributersData);
 
        context.commit("setOrderDistributers", orderDistributersData);
        const productDistirubtersPricesMapData = constructTransactionPriceMap(
          orderDistributersData
        );
 
        context.commit(
          "setProductDistirubtersPricesMap",
          productDistirubtersPricesMapData
        );

        let transactionDetailsData = { ...transactionResult?.transaction };
 
        context.commit(
          "setTransactionDetails",
          JSON.parse(JSON.stringify(transactionDetailsData))
        );

        let finalTransactionDetailsItems = processTrxItems(
          transactionDetailsData
        );
 
        if (
          transactionDetailsData.section.selling_type === "aggregate" &&
          transactionDetailsData.state === "pending" &&
          transactionDetailsData.receipts.length === 0
        ) {
          finalTransactionDetailsItems = selectBestDistibuters(
            finalTransactionDetailsItems
          );
        }

        context.commit("setTransactionItems", finalTransactionDetailsItems);

        const { transactionTotalAmount, transactionCreditTotalAmount } =
          calculateTransactionAmounts({
            transactionItems: finalTransactionDetailsItems,
            transactionReceipts: transactionDetailsData.receipts,
            state: transactionDetailsData.state,
          });
        context.commit("setTransactionTotalAmount", transactionTotalAmount);
        context.commit(
          "setTransactionTotalOriginalAmount",
          transactionTotalAmount
        );
        context.commit(
          "setTransactionCreditTotalAmount",
          transactionCreditTotalAmount
        );
        context.commit(
          "setTransactionCreditTotalOriginalAmount",
          transactionCreditTotalAmount
        );

        const {
          finalReceipts: calculatedInitialReceipts,
          selectedDistributers,
        } = calculateReceiptsAndSpecifyDistributers(
          finalTransactionDetailsItems
        );
        context.commit("setSelectedDistributers", selectedDistributers);
        context.commit(
          "setInitialTransactionReceipts",
          JSON.parse(JSON.stringify(calculatedInitialReceipts))
        );

        const reformattedReceipts = processAlreadySavedReceipts(
          calculatedInitialReceipts
        );
        context.commit("setTransactionReceipts", reformattedReceipts);
        context.dispatch("recalculateTotalMonetaryValue");
        let cancelledOrderProps = constructCancelledObject(reformattedReceipts);

        context.commit("setCancelledOrderProps", cancelledOrderProps);

        return [creditResult, transactionResult, distributerResult];
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async cancelReceiptClicked(context, receiptObject) {
      try {
        const receiptItems = receiptObject.items;

        const currentTrxItems = JSON.parse(
          JSON.stringify(context.getters.getTransactionItems)
        );
        receiptItems.forEach((item) => {
          const itemToBeUpdated = find(currentTrxItems, (trxItem) => {
            return matchTrxItemToReceiptItem(trxItem, item);
          });
          itemToBeUpdated.price = item.originalPrice;
          itemToBeUpdated.priceDifference = item.price - item.originalPrice;
          if (itemToBeUpdated.priceDifference < 0) {
            itemToBeUpdated.originalPrice = item.price;
            itemToBeUpdated.priceDifference = item.price - item.originalPrice;
          }
          itemToBeUpdated.distributerId = -1;
          itemToBeUpdated.provider.id = -1;
          itemToBeUpdated.provider.name = "Canceled Items";
          itemToBeUpdated.newlyCancelledItem = true;
        });
        context.commit("setTransactionItems", currentTrxItems);
        const { finalReceipts: newCalculatedReceipts } =
          calculateReceiptsAndSpecifyDistributers(currentTrxItems);
        // context.commit("setSelectedDistributers", selectedDistributers);

        const reformattedReceipts = processAlreadySavedReceipts(
          newCalculatedReceipts
        );
        context.commit("setTransactionReceipts", reformattedReceipts);
        context.dispatch("recalculateTotalMonetaryValue");
        let cancelledOrderProps = constructCancelledObject(reformattedReceipts);

        context.commit("setCancelledOrderProps", cancelledOrderProps);
        return context.dispatch("saveReceipts");
      } catch (error) {
        console.log("cancelReceiptClicked error :>> ", error);
      }
    },
    async saveReceipts(context, payload) {
      context.commit("setCanPrint", false);
      
      setTimeout(() => {
        context.commit("setCanPrint", true);
      }, 30000);
      let receipts = [];
      const currentReceipts = context.getters.getTransactionReceipts;
      if (currentReceipts.length) {
        currentReceipts.forEach((receipt) => {
           // calculate if the original price products are with the offer price
          const receiptsOriginalPriceCounter = {};
          receipt.items.forEach((item) => {
            receiptsOriginalPriceCounter[item.productId] =
              (receiptsOriginalPriceCounter[item.productId] ?? 0) + 1;
          });

          let receiptItem = {
            distributerId: receipt.receiptDistributer.id,
            discountAmount:
              receipt.receiptTotalAmount - receipt.receiptTotalOriginalAmount,
            id: receipt.receiptId,
            notes: receipt.notes,
            review_notes: receipt.review_notes,
            items: receipt.items.map(
              ({
                productId,
                price,
                creditAmount,
                quantity,
                originalPrice,
                unit,
                unitId,
                offer,
                is_original_price_with_offer,
              }) => {
                let finalReceiptItem = {
                  productId,
                  price,
                  creditAmount,
                  quantity,
                  discountAmount: price - originalPrice,
                  unitId: unitId ? unitId : unit?.id,
                };

                if (offer && offer.id && !context.getters.getIsDistributerSelected) {
                  finalReceiptItem.offer_id = offer.id;
                } else if (
                  receiptsOriginalPriceCounter[productId] >= 2 &&
                  receipt.receiptDistributer.id !== -1
                ) {
                   finalReceiptItem.is_original_price_with_offer = true;
                }
                // if this is cancelled Receipt use the  original price with offer flag returned from BE
                else if (receipt.receiptDistributer.id === -1) {
                  finalReceiptItem.is_original_price_with_offer =
                    is_original_price_with_offer;
                }
                return finalReceiptItem;
              }
            ),
          };

          receiptItem.isUpdated = checkReceiptUpdated(receipt);
          receipts.push(receiptItem);
        });
      }
 
      const transactionId = context.getters.getTransactionId;
      let requestBody = {
        receipts,
      };
      const currentTrxItems = context.getters.getTransactionItems;

      // calculate if the original price products are with the offer price
      const TransactionItemsOriginalPriceCounter = {};
      currentTrxItems.forEach((item) => {
        TransactionItemsOriginalPriceCounter[
          item.productId + item.distributerId
        ] =
          (TransactionItemsOriginalPriceCounter[
            item.productId + item.distributerId
          ] ?? 0) + 1;
      });

      const newTrxItems = currentTrxItems.filter((item) => item.newlyAddedItem);
       if (newTrxItems.length > 0) {
        requestBody.newTransactionItems = newTrxItems.map((item) => {
          return {
            distributerId: item.distributerId,
            price:
              item.offer && item.offer.price ? item.offer.price : item.price,
            productId: item.productId,
            quantity: item.quantity,
            isFaturaProduct: item.isFaturaProduct,
            isOffer: item.isOffer,
            unitId: item.unitId ? item.unitId : item.unit?.id,
            offerId: item.offer && item.offer.id ? item.offer.id : null,
            is_original_price_with_offer:
              !item.offer &&
              TransactionItemsOriginalPriceCounter[
                item.productId + item.distributerId
              ] >= 2,
          };
        });
      }
      let sendTrxItemsFlag = true;
      if (sendTrxItemsFlag) {
        let oldTrxItems = currentTrxItems.filter(
          (item) => !item.newlyAddedItem
        );
         requestBody.transactionItems = oldTrxItems.map((item) => {
          let returnedObject = {
            id: item.id,
            price: item.price,
            quantity: item.quantity,
            creditAmount: item.creditAmount,
          };
          if (item.offer && item.offer.id) {
            returnedObject.offer_id = item.offer.id;
          } else if (
            TransactionItemsOriginalPriceCounter[
              item.productId + item.distributerId
            ] >= 2
          ) {
            returnedObject.is_original_price_with_offer = true;
          }
          if (item.newlyCancelledItem) {
            returnedObject.state = "cancelled";
          }
          return returnedObject;
        });
      }
      if (payload) {
        requestBody.reason = payload.reason;
        if (payload.comment) requestBody.comment = payload.comment;
      }
      return transactions.newSaveTransactionReceipts(
        transactionId,
        requestBody
      );
    },
    async getLatestData(context) {
      try {
        const trxId = context.getters.getTransactionId;
        const transactionDetails = transactions?.getTransactionDetails(trxId);
        const transactionDistributers =
          transactions.getTransactionDistributers(trxId);
        const [transactionResult, distributerResult] = await Promise.all([
          transactionDetails,
          transactionDistributers,
        ]);
        context.commit("conditionalClearTrxData", [
          "transactionId",
          "creditConfig",
        ]);
        let orderDistributersData = [
          ...distributerResult?.transactionDistributes,
        ];
        orderDistributersData = flattenOrderDistributers(orderDistributersData);
        context.commit("setOrderDistributers", orderDistributersData);
        const productDistirubtersPricesMapData = constructTransactionPriceMap(
          orderDistributersData
        );
        context.commit(
          "setProductDistirubtersPricesMap",
          productDistirubtersPricesMapData
        );
        let transactionDetailsData = { ...transactionResult?.transaction };

        context.commit(
          "setTransactionDetails",
          JSON.parse(JSON.stringify(transactionDetailsData))
        );

        const finalTransactionDetailsItems = processTrxItems(
          transactionDetailsData
        );
        context.commit("setTransactionItems", finalTransactionDetailsItems);

        const { transactionTotalAmount, transactionCreditTotalAmount } =
          calculateTransactionAmounts({
            transactionItems: finalTransactionDetailsItems,
            transactionReceipts: transactionDetailsData.receipts,
            state: transactionDetailsData.state,
          });
        context.commit("setTransactionTotalAmount", transactionTotalAmount);
        context.commit(
          "setTransactionTotalOriginalAmount",
          transactionTotalAmount
        );
        context.commit(
          "setTransactionCreditTotalAmount",
          transactionCreditTotalAmount
        );
        context.commit(
          "setTransactionCreditTotalOriginalAmount",
          transactionCreditTotalAmount
        );
        const {
          finalReceipts: calculatedInitialReceipts,
          selectedDistributers,
        } = calculateReceiptsAndSpecifyDistributers(
          finalTransactionDetailsItems
        );
        context.commit("setSelectedDistributers", selectedDistributers);
        context.commit(
          "setInitialTransactionReceipts",
          JSON.parse(JSON.stringify(calculatedInitialReceipts))
        );

        const reformattedReceipts = processAlreadySavedReceipts(
          calculatedInitialReceipts
        );
        context.commit("setTransactionReceipts", reformattedReceipts);
        context.dispatch("recalculateTotalMonetaryValue");

        let cancelledOrderProps = constructCancelledObject(reformattedReceipts);

        context.commit("setCancelledOrderProps", cancelledOrderProps);

        return [transactionResult, distributerResult];
      } catch (error) {
        console.log("getLatestData error :>> ", error);
      }
    },
    async updateTrxStatus(context, newStatus) {
      const trxId = context.getters.getTransactionId;

      return transactions.newChangeTransactionStatus(trxId, newStatus);
    },
    recalculateReceipts(context) {
      const finalTransactionDetailsItems = JSON.parse(
        JSON.stringify(context.getters.getTransactionItems)
      );
      const { finalReceipts: calculatedInitialReceipts, selectedDistributers } =
        calculateReceiptsAndSpecifyDistributers(finalTransactionDetailsItems);
      context.commit("setSelectedDistributers", selectedDistributers);

      const reformattedReceipts = processAlreadySavedReceipts(
        calculatedInitialReceipts
      );
      context.commit("setTransactionReceipts", reformattedReceipts);

      let cancelledOrderProps = constructCancelledObject(reformattedReceipts);
      context.dispatch("recalculateTotalMonetaryValue");
      context.commit("setCancelledOrderProps", cancelledOrderProps);

      // const currentState = context.getters.getTransactionDetails.state;

      // const { transactionTotalAmount, transactionCreditTotalAmount } =
      //   calculateTransactionAmounts({
      //     transactionItems: finalTransactionDetailsItems,
      //     transactionReceipts: reformattedReceipts,
      //     state: currentState,
      //   });
      // context.commit("setTransactionTotalAmount", transactionTotalAmount);
      // context.commit(
      //   "setTransactionTotalOriginalAmount",
      //   transactionTotalAmount
      // );
      // context.commit(
      //   "setTransactionCreditTotalAmount",
      //   transactionCreditTotalAmount
      // );
      // context.commit(
      //   "setTransactionCreditTotalOriginalAmount",
      //   transactionCreditTotalAmount
      // );
    },
    tableItemSteal(context, clickedRow) {
      const currentTrxItems = JSON.parse(
        JSON.stringify(context.getters.getTransactionItems)
      );
      const selectedDistributer = clickedRow.selectedDistributer;
      const currentItem = clickedRow.currentItem;
      const newlyCancelledItemFlag = clickedRow.newlyCancelledItem;

      const itemToBeUpdated = find(currentTrxItems, (trxItem) => {
        return matchTrxItemToReceiptItem(trxItem, currentItem);
      });
      if (!currentItem.newlyAddedItem) {
        currentItem.price = selectedDistributer.price;
        itemToBeUpdated.price = selectedDistributer.price;

        currentItem.priceDifference =
          currentItem.price - currentItem.originalPrice;
        itemToBeUpdated.priceDifference =
          currentItem.price - currentItem.originalPrice;

        if (currentItem.priceDifference < 0) {
          currentItem.originalPrice = currentItem.price;
          currentItem.priceDifference =
            currentItem.price - currentItem.originalPrice;
        }
        if (itemToBeUpdated.priceDifference < 0) {
          itemToBeUpdated.originalPrice = itemToBeUpdated.price;
          itemToBeUpdated.priceDifference =
            itemToBeUpdated.price - itemToBeUpdated.originalPrice;
        }
        currentItem.distributerId = selectedDistributer.id;
        currentItem.provider.id = selectedDistributer.id;
        currentItem.provider.name = selectedDistributer.name;

        itemToBeUpdated.distributerId = selectedDistributer.id;
        itemToBeUpdated.provider.id = selectedDistributer.id;
        itemToBeUpdated.provider.name = selectedDistributer.name;

        if (newlyCancelledItemFlag) {
          currentItem.newlyCancelledItem = true;
          itemToBeUpdated.newlyCancelledItem = true;
        }
      } else {
        remove(currentTrxItems, {
          productId: currentItem.productId,
          newlyAddedItem: true,
        });
      }
      context.commit(
        "setTransactionItems",
        JSON.parse(JSON.stringify(currentTrxItems))
      );
      context.dispatch("recalculateReceipts");
    },
    recalculateTotalMonetaryValue(context) {
      const finalTransactionDetailsItems = context.getters.getTransactionItems;
      const finalTransactionReceipts = context.getters.getTransactionReceipts;
      const transactionState = context.getters.getTransactionDetails;
      const { transactionTotalAmount, transactionCreditTotalAmount } =
        calculateTransactionAmounts({
          transactionItems: finalTransactionDetailsItems,
          transactionReceipts: finalTransactionReceipts,
          state: transactionState.state,
        });
      context.commit("setTransactionTotalAmount", transactionTotalAmount);
      context.commit(
        "setTransactionTotalOriginalAmount",
        transactionTotalAmount
      );
      context.commit(
        "setTransactionCreditTotalAmount",
        transactionCreditTotalAmount
      );
      context.commit(
        "setTransactionCreditTotalOriginalAmount",
        transactionCreditTotalAmount
      );
    },
  },
};

export default trxStore;