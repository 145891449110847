import { _ } from "core-js";
import trxStore from "../store/modules/transactionDetails.store";

import {
  groupBy,
  forOwn,
  find,
  isEmpty,
  // forEach,
  cloneDeep,
  isEqual,
  filter,
  isNull,
} from "lodash";

export const faturaPrice = (price) => {
  let faturaPrice = 0;
  //check if it transaction has a bundle or not
  const transactionDetails = trxStore.state.transactionDetails;
  const creditConfig = trxStore.state.creditConfig;

  if (!transactionDetails.bundle) {
    faturaPrice =
      price *
      (transactionDetails.method == "credit"
        ? 1 + Number.parseFloat(creditConfig.Credit_Premium)
        : 1);
  } else {
    faturaPrice =
      price *
      (transactionDetails.method == "credit"
        ? 1 + Number.parseFloat(transactionDetails.bundle.premiumPercentage)
        : 1);
  }
  // faturaPrice = (Math.round(faturaPrice * 4) / 4).toFixed(2);
  faturaPrice = _.round(faturaPrice, 2);
  return faturaPrice;
};
export const calculateTransactionAmounts = ({
  transactionItems,
  transactionReceipts,
  state,
}) => {
  let transactionTotalAmount, transactionCreditTotalAmount;

  if (state == "pending") {
    transactionTotalAmount = transactionItems.reduce((sum, item) => {
      return sum + item.originalPrice * item.quantity;
    }, 0);
    transactionCreditTotalAmount = transactionItems.reduce((sum, item) => {
      return sum + faturaPrice(item.originalPrice) * item.quantity;
    }, 0);
  } else {
    transactionTotalAmount = transactionReceipts.reduce((sum, receipt) => {
      let totalReceiptItemPrice = receipt.items.reduce((receiptTotal, item) => {
        return receiptTotal + item.price * item.quantity;
      }, 0);
      return sum + totalReceiptItemPrice;
    }, 0);
    transactionCreditTotalAmount = transactionReceipts.reduce(
      (sum, receipt) => {
        let totalReceiptItemPrice = receipt.items.reduce(
          (receiptTotal, item) => {
            return receiptTotal + faturaPrice(item.price) * item.quantity;
          },
          0
        );
        return sum + totalReceiptItemPrice;
      },
      0
    );
  }

  return { transactionTotalAmount, transactionCreditTotalAmount };
};
export const constructTransactionPriceMap = (orderDistributersData) => {
  let output = {};
  for (const singleDistributerId in orderDistributersData) {
    const distributerObject = orderDistributersData[singleDistributerId];
    distributerObject.products.forEach((distributerProduct) => {
      if (!output[distributerProduct.productId]) {
        output[distributerProduct.productId] = {};
      }
      output[distributerProduct.productId][distributerProduct.distributerId] =
        distributerProduct.cashPrice;
    });
  }
  return output;
};

export const calculateReceiptsAndSpecifyDistributers = (transactionItems) => {
    try {
    let groupedReceipts = groupBy(transactionItems, "distributerId");
    let finalReceipts = []; //groupedReceipts
    let selectedDistributers = Object.keys(groupedReceipts);
     for (let i = 0; i < selectedDistributers.length; i++) {
      const currentDistributerId = selectedDistributers[i];
      const currentReceipt = groupedReceipts[currentDistributerId];
 
      const element = currentReceipt;
        let receiptTotalAmount = element.reduce((sum, n) => {
        return sum + n.price * n.quantity;
      }, 0);
       let receiptTotalAmountVisual = element.reduce((sum, n) => {
        return sum + faturaPrice(n.price) * n.quantity;
      }, 0);
      let receiptTotalOriginalAmount = element.reduce((sum, n) => {
        return sum + n.originalPrice * n.quantity;
      }, 0);

      const receiptDistributer = element[0].provider;
      const currentOrderDistributers = JSON.parse(
        JSON.stringify(trxStore.state.orderDistributers)
      );
      if (currentOrderDistributers[receiptDistributer.id]) {
        receiptDistributer.minOrder =
          currentOrderDistributers[receiptDistributer.id].minOrder;
      } else {
        receiptDistributer.minOrder = 0;
      }     
      let currentDistributerAvailableItemsIds = [];
      if (
        receiptDistributer.id !== -1 &&
        currentOrderDistributers[receiptDistributer.id]
      ) {
        currentDistributerAvailableItemsIds = Object.keys(
          currentOrderDistributers[receiptDistributer.id]?.productsPriceMap
        );
      }
      let final = {};
      const productDistirubtersPricesMap = JSON.parse(
        JSON.stringify(trxStore.state.productDistirubtersPricesMap)
      );
      currentDistributerAvailableItemsIds.forEach((productId) => {
        final[productId] = productDistirubtersPricesMap[productId];
      });
      const transactionDetails = JSON.parse(
        JSON.stringify(trxStore.state.transactionDetails)
      );
      const currentTrxItems = JSON.parse(JSON.stringify(transactionItems));
       let stealItems = {};
      Object.keys(final).forEach((productIdKey) => {
         const productDistributersPricesHashmap = final[productIdKey];
        Object.keys(productDistributersPricesHashmap).forEach(
          (distributerIdKey) => {
            let product = find(currentTrxItems, {
              productId: parseInt(productIdKey),
            });
            if (
              selectedDistributers.includes(distributerIdKey) &&
              distributerIdKey != receiptDistributer.id &&
              product?.distributerId == distributerIdKey
            ) {
              if (!stealItems[distributerIdKey])
                stealItems[distributerIdKey] = [];
              let stolenProduct = find(
                currentOrderDistributers[receiptDistributer.id].products,
                { productId: parseInt(productIdKey) }
              );
              stealItems[distributerIdKey].push({
                productId: productIdKey,
                distributerId: distributerIdKey,
                price: stolenProduct.cashPrice,
                product,
                distributer: currentOrderDistributers[distributerIdKey],
                priceDifference: stolenProduct.cashPrice - product.price,
              });
            }
          }
        );
      });

      let receiptObject = {
        items: element,
        receiptDistributer,
        receiptTotalAmount,
        receiptTotalAmountVisual,
        receiptTotalOriginalAmount,
        stealItems: isEmpty(stealItems) ? false : stealItems,
      };

      const currentTransactionReceipts = trxStore.state.transactionReceipts;
       let lookupReceipts;
      if (currentTransactionReceipts && currentTransactionReceipts.length) {
        lookupReceipts = JSON.parse(JSON.stringify(currentTransactionReceipts));
      } else {
        lookupReceipts = JSON.parse(
          JSON.stringify(transactionDetails.receipts)
        );
      }
      let extraReceiptDetails = find(lookupReceipts, (singleReceipt) => {
        let rDistributer = -1;
        if (singleReceipt.provider) {
          rDistributer = singleReceipt.provider.id;
        }
        return rDistributer === receiptDistributer.id;
      });
      if (extraReceiptDetails) { 
        receiptObject["receiptId"] =
          extraReceiptDetails.id || extraReceiptDetails.receiptId;
        receiptObject["receiptStatus"] =
          extraReceiptDetails.status || extraReceiptDetails.receiptStatus;
        receiptObject["updatedAt"] = extraReceiptDetails.updatedAt;
        receiptObject["notes"] = extraReceiptDetails.notes;
        receiptObject['cashbackRedemptionAmount'] = extraReceiptDetails.cashbackRedemptionAmount
        receiptObject["undeliver_reason"] =
          extraReceiptDetails.undeliver_reason;
        receiptObject["validate_undeliver_reason"] =
          extraReceiptDetails.validate_undeliver_reason;
        receiptObject["review_notes"] = extraReceiptDetails.review_notes;
        receiptObject["review_score"] = extraReceiptDetails.review_score;
        receiptObject["actualAmount"] = extraReceiptDetails.actualAmount;
        receiptObject["provider"] = extraReceiptDetails.provider;
        receiptObject["createdAt"] = extraReceiptDetails.createdAt;
        receiptObject["expectedDeliveryDate"] =
          extraReceiptDetails.expectedDeliveryDate;
        receiptObject["requiredDeliveryDate"] =
          extraReceiptDetails.requiredDeliveryDate;
        receiptObject["showChangeButtonFlag"] =
          extraReceiptDetails.hasOwnProperty("showChangeButtonFlag")
            ? extraReceiptDetails.showChangeButtonFlag
            : false;
        receiptObject["promocode"] = extraReceiptDetails.promocode;
         receiptObject["target"] = extraReceiptDetails.target;
      }
      finalReceipts.push(receiptObject);
    }
    return { selectedDistributers, finalReceipts };
  } catch (error) {
    console.log("error in calculateReceiptsAndSpecifyDistributers :>> ", error);
  }
};

export const getBestProductPrice = (item) => {
  const currentProductDistirubtersPricesMap =
    trxStore.getters.getProductDistirubtersPricesMap;
  let bestPriceItem = {
    price:
      currentProductDistirubtersPricesMap[item.productId][
        Object.keys(currentProductDistirubtersPricesMap[item.productId])[0]
      ],
    distributerId: Object.keys(
      currentProductDistirubtersPricesMap[item.productId]
    )[0],
    name: this.orderDistributers[
      Object.keys(currentProductDistirubtersPricesMap[item.productId])[0]
    ].name,
  };
  const currentOrderDistributers = trxStore.getters.getOrderDistributers;
  forOwn(currentOrderDistributers, (value, key) => {
    const price = currentProductDistirubtersPricesMap[item.productId][key];
    if (price && price < bestPriceItem.price)
      bestPriceItem = {
        price: price,
        distributerId: key,
        name: value.name,
      };
  });
  return bestPriceItem;
};

export const processAlreadySavedReceipts = (orderReceipts) => {
   const transactionDetails = { ...trxStore.state.transactionDetails };
 
  const newOrderReceipts = orderReceipts.map((singleReceipt) => {
     if (!singleReceipt.provider) {
      singleReceipt.provider = {
        id: -1,
        name: "Canceled Order",
      };
      // show undeliver reason for cancelled receipts
      const cancelledReceipts = filter(transactionDetails.receipts, {
        status: "cancelled",
      });
      cancelledReceipts.forEach((singleCancelledReceipt) => {
        if (singleCancelledReceipt.undeliver_reason) {
          if (singleReceipt.undeliver_reason) {
            singleReceipt.undeliver_reason += ` - ${singleCancelledReceipt.undeliver_reason}`;
          } else {
            singleReceipt.undeliver_reason =
              singleCancelledReceipt.undeliver_reason;
          }
        }
      });
    }
    if (
      singleReceipt.requiredDeliveryDate &&
      singleReceipt.requiredDeliveryDate.length > 9
    ) {
      singleReceipt.requiredDeliveryDate =
        singleReceipt.requiredDeliveryDate.substring(0, 10);
    }
    return singleReceipt;
  });
  return newOrderReceipts;
};

const constructPossibleDistributers = (distirubtersMap) => {
  const orderDistributers = JSON.parse(
    JSON.stringify(trxStore.state.orderDistributers)
  );
  let finalArray = [];
  if (distirubtersMap)
    Object.keys(distirubtersMap).forEach((distributerIdKey) => {
      finalArray.push({
        id: parseInt(distributerIdKey),
        price: distirubtersMap[distributerIdKey],
        name: orderDistributers[distributerIdKey].name,
      });
    });
  return finalArray;
};
export const processTrxItems = (rawTransactionDetails) => {
  const productDistirubtersPricesMap =
    trxStore.state.productDistirubtersPricesMap;

  const finalItems = [...rawTransactionDetails?.items].map((item) => {
    item.originalPrice = item.price;
    item.originalQuantity = item.quantity;
    item.priceDifference = item.price - item.originalPrice;
    item.possibleDistributers = constructPossibleDistributers(
      productDistirubtersPricesMap[item.productId]
    );

    return item;
  });
 
  const orderReceipts = [...rawTransactionDetails?.receipts];

  if (orderReceipts.length === 0) return finalItems;

  let allReceiptsItems = [];
  for (let receipt = 0; orderReceipts.length > receipt; receipt++) {
    orderReceipts[receipt].items.forEach((item) => {
      item.originalPrice = item.price; 
      item.originalQuantity = item.quantity;
      item.priceDifference = `${(+item.price - +item.originalPrice).toFixed(2)}`;
      item.possibleDistributers = constructPossibleDistributers(
        productDistirubtersPricesMap[item.productId]
      );
      item.is_offer = item?.offer ? 1 : 0;
      item.distributerId = orderReceipts[receipt]?.providerId ?? -1;
      item.transactionId = orderReceipts[receipt]?.transactionId;
      item.provider = {
        id: orderReceipts[receipt]?.provider?.id ?? -1,
        name: orderReceipts[receipt]?.provider?.name ?? "Canceled Order",
      };
      item.status = orderReceipts[receipt]?.status;
      item.state = orderReceipts[receipt]?.status;
      item.createdAt = orderReceipts[receipt]?.createdAt;
      item.updatedAt = orderReceipts[receipt]?.updatedAt;
      allReceiptsItems.push(item);
    });
  }
 
   return allReceiptsItems;

  // let allReceipts = {};
  // for (let item = 0; orderReceipts.length > item; item++) {
  //   let currentReceiptDistributer =
  //     orderReceipts[item]?.providerId || orderReceipts[item]?.provider?.id
  //       ? {
  //           id: orderReceipts[item]?.provider?.id ?? -1,
  //           name: orderReceipts[item]?.provider?.name ?? "Canceled Order",
  //         }
  //       : orderReceipts[item]?.providerId;

  //   if (orderReceipts[item].status === "cancelled") {
  //     currentReceiptDistributer = {
  //       id: -1,
  //       name: "Canceled Order",
  //     };
  //   }
  //   allReceipts = {
  //     ...allReceipts,
  //     [orderReceipts[item]?.id]: {
  //       orderReceipts: orderReceipts[item],
  //       currentReceiptDistributer: { ...currentReceiptDistributer },
  //     },
  //   };
  // }
  // console.log("🚀 ~ processTrxItems ~ allReceipts:", allReceipts);

  // const getReceiptsItemsFromTransactionItems = finalItems.filter((item) => {
  //   return Object.keys(allReceipts).some((receipt) => {
  //     if (allReceipts[receipt].currentReceiptDistributer?.id !== -1) {
  //       return (
  //         item.distributerId ===
  //         allReceipts[receipt].currentReceiptDistributer?.id
  //       );
  //     }
  //     return allReceipts[receipt].orderReceipts.items.some(
  //       (receiptItem) => receiptItem.productId === item.productId
  //     );
  //   });
  // });

  // Object.keys(allReceipts).map((receipts) => {
  //   let originalTransactionItem = getReceiptsItemsFromTransactionItems.filter(
  //     (trxItem) => {
  //       if (allReceipts[receipts].orderReceipts.status !== "cancelled") {
  //         const items = allReceipts[receipts]?.orderReceipts?.items.some(
  //           (item) => {
  //             item.providerId =
  //               allReceipts[receipts].currentReceiptDistributer?.id;
  //             return matchTrxItemToReceiptItem2(trxItem, item);
  //           }
  //         );

  //         return items;
  //       } else {
  //         const items = allReceipts[receipts]?.orderReceipts.items.some(
  //           (item) => {
  //             return matchTrxItemToReceiptItem(trxItem, item);
  //           }
  //         );

  //         return items;
  //       }
  //     }
  //   );
  //   originalTransactionItem.map((item) => {
  //     if (allReceipts[receipts].currentReceiptDistributer?.id !== -1) {
  //       allReceipts[receipts]?.orderReceipts.items.forEach((receipt) => {
  //         if (receipt.id === item.id) {
  //           item.price = receipt.price;
  //           item.quantity = receipt.quantity;
  //         }
  //       });
  //     }
  //     item.priceDifference = (+item?.price - +item?.originalPrice).toFixed(2);
  //     item.distributerId = allReceipts[receipts].currentReceiptDistributer?.id;
  //     item.provider.id = allReceipts[receipts].currentReceiptDistributer?.id;
  //     item.provider.name =
  //       allReceipts[receipts].currentReceiptDistributer?.name;
  //   });

  //   return originalTransactionItem;
  // });
  // console.log("🚀 ~ processTrxItems ~ getReceiptsItemsFromTransactionItems:", getReceiptsItemsFromTransactionItems)

  // return getReceiptsItemsFromTransactionItems;
};
 
export const constructCancelledObject = (receiptsArray) => {
  let cancelledOrderItems = receiptsArray.filter((r) => {
    return r.receiptDistributer.id === -1;
  })[0];

  let cancelledOrderProps;
  const totalAmount = trxStore.state.transactionTotalAmount;
  const transactionItems = trxStore.state.transactionItems;

  if (cancelledOrderItems && cancelledOrderItems.items.length) {
    cancelledOrderProps = {
      amount: cancelledOrderItems.receiptTotalAmount,
      amountPercentge:
        (cancelledOrderItems.receiptTotalAmount / totalAmount) * 100,
      cancelledItemsCount: cancelledOrderItems.items.length,
      cancelledItemsCountPercentage:
        (cancelledOrderItems.items.length / transactionItems.length) * 100,
    };
  } else {
    cancelledOrderProps = {};
  }
  return cancelledOrderProps;
};

export const checkReceiptUpdated = (receipt) => {
  const originalReceipts = JSON.parse(
    JSON.stringify(trxStore.state.initialTransactionReceipts)
  );
  const currentTransaction = JSON.parse(
    JSON.stringify(trxStore.state.transactionDetails)
  );
  if (currentTransaction.state == "pending") return false;

  if (originalReceipts.length > 0) {
    // get the orginal receipt before any update
    const originalReceipt = originalReceipts.filter(
      (r) => r.receiptId == receipt.receiptId
    )[0];

    let isUpdated = false;
    if (currentTransaction.state && currentTransaction.state != "pending") {
      if (receipt.notes && originalReceipt.notes != receipt.notes) {
        isUpdated = true;
        return isUpdated;
      }
    }
    if (originalReceipt && originalReceipt.items.length == receipt.items.length)
      for (let i = 0; i < originalReceipt.items.length; i++) {
        const orginalRItem = originalReceipt.items[i];
        let updatedReceiptItem = cloneDeep(
          receipt.items.find((item) => item.id == orginalRItem.id)
        );

        //remove uncessry data to make comparison easier
        if (updatedReceiptItem) {
          orginalRItem.possibleDistributers = undefined;
          updatedReceiptItem.possibleDistributers = undefined;
        }
        //Comparing current receipt item with the orginal receipt item OR if item existed
        if (!updatedReceiptItem || !isEqual(updatedReceiptItem, orginalRItem)) {
          isUpdated = true;
          break;
        }
      }
    else if (
      !originalReceipt ||
      originalReceipt.items.length != receipt.items.length
    )
      isUpdated = true;

    return isUpdated;
  }
};

export const selectBestDistibuters = (trxItems) => {
  for (let i = 0; i < trxItems.length; i++) {
    const product = trxItems[i];
    let bestPriceDistributer = getbestPriceFromDistibuters(product);
    product.price = bestPriceDistributer.price;
    product.priceDifference = (product.price - product.originalPrice).toFixed(
      2
    );
    if (product.priceDifference < 0) {
      product.originalPrice = product.price;
      product.priceDifference = 0;
    }
    product.distributerId = bestPriceDistributer.distributerId;
    product.provider.id = bestPriceDistributer.distributerId;
    product.provider.name = bestPriceDistributer.name;
  }
  return JSON.parse(JSON.stringify(trxItems));
};

export const getbestPriceFromDistibuters = (product) => {
  const productPriceMap = JSON.parse(
    JSON.stringify(trxStore.state.productDistirubtersPricesMap)
  );
  const orderDistributers = JSON.parse(
    JSON.stringify(trxStore.state.orderDistributers)
  );
  let bestPriceItem = {
    price:
      productPriceMap[product.productId][
        Object.keys(productPriceMap[product.productId])[0]
      ],
    distributerId: Object.keys(productPriceMap[product.productId])[0],
    name: orderDistributers[Object.keys(productPriceMap[product.productId])[0]]
      .name,
  };
  forOwn(orderDistributers, (value, key) => {
    const price = productPriceMap[product.productId][key];
    if (price && price < bestPriceItem.price)
      bestPriceItem = {
        price: price,
        distributerId: key,
        name: value.name,
      };
  });
  return bestPriceItem;
};

function constructProductPriceMap(products) {
  let output = {};
  products.forEach((p) => {
    output[p.productId] = p.cashPrice;
  });

  return output;
}

export const flattenOrderDistributers = (orderDistributers) => {
  let output = {};

  orderDistributers.forEach((distributer) => {
     output[distributer.distributerId] = {
      id: distributer.distributerId,
      name: distributer.name,
       minOrder: distributer.minOrder,
      products: distributer.products,
      areas: distributer.areas,
      blacklist: distributer.blacklist,
      original: distributer.original,
      receipt: distributer.receipt,
      productsPriceMap: constructProductPriceMap(distributer.products),
      entityWorkingHours: distributer.entityWorkingHours,
      landline: distributer.landline,
      phone: distributer.phone,
      secondPhone: distributer.secondPhone,
      productsCategory: distributer.productsCategory,
    };
  });
  return output;
};
export const matchTrxItemToReceiptItem2 = (transactionItem, receiptItem) => {
  if (
    receiptItem?.status === "cancelled" ||
    transactionItem?.state === "cancelled"
  ) {
    return true;
  }
  let finalFlag = transactionItem?.distributerId === receiptItem.providerId;
  if (isNull(transactionItem.offer)) {
    finalFlag = finalFlag && isNull(receiptItem.offer);
  } else {
    finalFlag =
      finalFlag &&
      receiptItem.offer &&
      transactionItem.offer.id == receiptItem.offer?.id;
  }

  return finalFlag;
};

export const matchTrxItemToReceiptItem = (transactionItem, receiptItem) => {
  let finalFlag = transactionItem.productId === receiptItem.productId;
  if (isNull(transactionItem.offer)) {
    finalFlag = finalFlag && receiptItem.price === transactionItem.price;
  } else {
    finalFlag = finalFlag && receiptItem.price === transactionItem.price;
  }
  return finalFlag;
};
// export const matchTrxItemToReceiptItem = (transactionItem, receiptItem) => {
//   let finalFlag = transactionItem.productId == receiptItem.productId;
//   if (isNull(transactionItem.offer)) {
//     finalFlag = finalFlag && isNull(receiptItem.offer);
//   } else {
//     finalFlag =
//       finalFlag &&
//       receiptItem.offer &&
//       transactionItem.offer.id == receiptItem.offer?.id;
//   }
//   return finalFlag;
// };
export const impersontationMatchTrxItemToReceiptItem = (
  transactionItem,
  receiptItem
) => {
  let finalFlag =
    transactionItem.productId == receiptItem.productId &&
    transactionItem.unit &&
    transactionItem.unit.name == receiptItem.unit &&
    transactionItem.unit.id == receiptItem.unitId;

  if (isNull(transactionItem.offer)) {
    finalFlag = finalFlag && isNull(receiptItem.offer);
  } else {
    finalFlag =
      finalFlag &&
      receiptItem.offer &&
      transactionItem.offer.id == receiptItem.offer?.id;
  }
  return finalFlag;
};
export const impersontationMatchReceiptItemToFetchedItem = (
  receiptItem,
  fetchedItem
) => {
  let finalFlag =
    receiptItem.productId == fetchedItem.productId &&
    receiptItem.unit &&
    receiptItem.unit.name == fetchedItem.unit &&
    receiptItem.unit.id == fetchedItem.unitId;

  if (isNull(receiptItem.offer)) {
    finalFlag = finalFlag && isNull(fetchedItem.offer);
  } else {
    finalFlag =
      finalFlag &&
      fetchedItem.offer &&
      receiptItem.offer.id == fetchedItem.offer?.id;
  }
  return finalFlag;
};
