import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getads(page = 1, filter = {}) {
  return $http
    .get("/admin/ads", {
      headers: authHeader(),
      params: { page, filter: processFilters(filter) },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        ads: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}
function createad(data) {
  return $http.post(
    "/admin/ads",
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function deletead({ id, type, provider }) {
  return $http.delete(
    `/admin/ads/${id}`,

    {
      headers: authHeader(),
      params: { type, provider },
    }
  );
}
function getSingleAd({ id, type, provider }) {
  return $http.get(`/admin/ads/${id}`, {
    headers: authHeader(),
    params: { type, provider },
  });
}

function editAd({ id }, data) {
  return $http.patch(
    `/admin/ads/${id}`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
}

function deleteAdsBulk(adsIDs) {
  return $http.delete(`/admin/ads/bulk`, {
    headers: authHeader(),
    data: {
      ids: adsIDs,
    },
  });
}
function bulkUploadMedias(files) {
  return $http.post(`/admin/ads/image-upload`, files, {
    headers: authHeader(),
  });
}
function importAdsSheet(toBeSentFormData, action) {
  return $http.post(`/admin/ads/import?action=${action}`, toBeSentFormData, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
function importEntitiesSheet(toBeSentFormData, id) {
  return $http.post(`/admin/ads/${id}/entity/import`, toBeSentFormData, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
function exportAdsSheet(action, filters) {
  let paramsObject = {
    action,
    filters: processFilters(filters),
  };
  return $http.get(`/admin/ads/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { ...paramsObject },
  });
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters["isActive"]) {
    let valueToBeSent = filters["isActive"] === "Active" ? true : false;
    filters["isActive"] = valueToBeSent;
  }
  if (filters["areas"]) {
    if (filters["areas"].length == 0) {
      delete filters.areas;
    }
  }
  if (filters["regions"]) {
    if (filters["regions"].length == 0) {
      delete filters.regions;
    }
  }
  if (filters.createdAt === null) {
    delete filters.createdAt;
  }
  return filters;
}
export const MarktingService = {
  ads: {
    getads,
    createad,
    deletead,
    getSingleAd,
    editAd,
    deleteAdsBulk,
    bulkUploadMedias,
    importAdsSheet,
    exportAdsSheet,
    importEntitiesSheet,
  },
};
