<template>
  <el-container class="bigcontainer" style="height: 100vh">
    <!-- <div style=" background-color: #545c64">
      <el-aside style="max-width: 1000px; width:auto; height: 100vh;">
        <sidebar />
      </el-aside>
    </div> -->
    <el-container>
      <el-header class="header-container">
        <div>
          <img src="../assets/fatura-logo.png" class="tiny-logo" alt="" />
        </div>
        <sidebar class="hidden-md-and-down" />
        <div class="user-profile">
          <span
            v-text="currentUser.name"
            style="padding-right: 4px"
            class="u-right-margin--2x"
          ></span>

          <!-- <the-lang-switcher /> -->

          <el-dropdown>
            <el-avatar icon="el-icon-user-solid" size="small"></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <a @click="logoutCurrentUser()">{{
                  $t("dashboardComponentDropdownOptionLabelLogout")
                }}</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-popover
          class="hidden-lg-and-up"
          placement="bottom"
          width="200"
          trigger="click"
        >
          <sidebar mode="vertical" />
          <el-button icon="el-icon-s-operation" slot="reference"></el-button>
        </el-popover>
      </el-header>
      <el-main class="main-container">
        <slot />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from "@/components/sideBar";
import { mapGetters, mapActions } from "vuex";
// import TheLangSwitcher from "../components/TheLangSwitcher.vue";
export default {
  components: {
    Sidebar,
    // TheLangSwitcher,
  },
  computed: {
    ...mapGetters({
      currentUser: "Authentication/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      logoutUser: "Authentication/logout",
    }),
    logoutCurrentUser() {
      this.logoutUser();
      this.$store.dispatch("reset");
    },
  },
};
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.bigcontainer {
  background-color: #f5f6fa;
}
.header-container {
  display: flex;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
}
.main-container {
  margin-top: 1.2rem;
}
.tiny-logo {
  height: 46px;
}
.el-header {
  height: 70px !important;
  // box-shadow: 1px 2px 8px 1px #ddd;
}
.user-profile {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
}
</style>
