import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getcategories(filters, page = 1) {
  return $http
    .get("/admin/categories", {
      headers: authHeader(),
      params: { page, ...filters },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        categories: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}
function createCategory(data) {
  return $http.post("/admin/categories", data, {
    headers: authHeader(),
  });
}
function createSubCategory(data) {
  return $http.post("/admin/subcategories", data, {
    headers: authHeader(),
  });
}
function getCategory(id) {
  return $http.get(`/admin/categories/${id}`, {
    headers: authHeader(),
  });
}
function updateCategory(id, data) {
  return $http.put(`/admin/categories/${id}`, data, {
    headers: authHeader(),
  });
}
function deleteCategory(id) {
  return $http.delete(`/admin/categories/${id}`, {
    headers: authHeader(),
  });
}
function getSubCategory(id) {
  return $http.get(`/admin/subcategories/${id}`, {
    headers: authHeader(),
  });
}
function updateSubCategory(id, data) {
  return $http.put(`/admin/subcategories/${id}`, data, {
    headers: authHeader(),
  });
}
function deleteSubCategory(id) {
  return $http.delete(`/admin/subcategories/${id}`, {
    headers: authHeader(),
  });
}

function bulkEditCategoriesImages(data) {
  return $http.post(`/admin/categories/image-upload`, data, {
    headers: authHeader(),
  });
}

export const categoriesService = {
  getcategories,
  createCategory,
  getCategory,
  createSubCategory,
  updateCategory,
  deleteCategory,
  updateSubCategory,
  deleteSubCategory,
  getSubCategory,
  bulkEditCategoriesImages,
};
