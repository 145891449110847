import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getProducts(filters, page = 1) {
  return $http
    .get("/admin/product", {
      headers: authHeader(),
      params: { page, filter: filters },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        products: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}
// eslint-disable-next-line no-unused-vars
function getDraftProducts(filters, page = 1) {
  return $http
    .get("/admin/product/draft-products", {
      headers: authHeader(),
      // params: { page, filter: filters },
    })
    .then((res) => {
      const {
        data: { products, pages, count, next },
      } = res;

      return {
        products: products,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}

function getproduct(id) {
  return $http.get(`/admin/product/${id}`, {
    headers: authHeader(),
  });
}
function addProduct(productObj) {
  return $http
    .post("/admin/product", productObj, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
}
function editProduct(productId, productObj) {
  return $http
    .put(`/admin/product/${productId}`, productObj, {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
}
function importProducts(data) {
  return $http.post("/admin/product/import", data, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
function importProductsUnits(data) {
  return $http.post("/admin/productUnit/import", data, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
function exportProducts(action, filters) {
  const paramsObject = {
    action,
  };
  if (filters) {
    paramsObject.filters = filters;
  }
  return $http.get("/admin/product/export", {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: paramsObject,
  });
}
function exportProductsUnits(action, filters) {
  const paramsObject = {
    action,
  };
  if (filters) {
    paramsObject.filters = filters;
  }
  return $http.get("/admin/productUnit/export", {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: paramsObject,
  });
}
function bulkEditProductsImages(data) {
  return $http.post(`/admin/product/image-upload`, data, {
    headers: authHeader(),
  });
}
function assignMultipleUnitsOnProduct(data) {
  return $http.post(
    `/admin/productUnit`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function getUnitsofCertainProduct(productId) {
  return $http.get(`/admin/productUnit/product/${productId}`, {
    headers: authHeader(),
  });
}
function bulkUpdateProductUnits(data) {
  return $http.patch(
    `/admin/productUnit/updateMany`,
    { data },
    {
      headers: authHeader(),
    }
  );
}
function UpdateOneProductUnit({ productId, unitId, newData }) {
  return $http.patch(
    `/admin/productUnit/${productId}/${unitId}`,
    { newData },
    {
      headers: authHeader(),
    }
  );
}
function deleteProductUnit({ productId, unitId }) {
  return $http.delete(`/admin/productUnit/${productId}/${unitId}`, {
    headers: authHeader(),
  });
}

function getProvidersOfCertainProduct(productId) {
  return $http.get(`/admin/product/${productId}/provider`, {
    headers: authHeader(),
  });
}
function getProvidersAssignedToProductUnit({ unitId, productId }) {
  return $http.get(`/admin/entityProducts/entitiesProductUnitCount`, {
    headers: authHeader(),
    params: { unitId, productId },
  });
}

function importDeleteProductsSheet(toBeSentFormData) {
  return $http.post(
    `/admin/entityProducts/import?action=delete`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}

function exportDeleteProductsSheet() {
  return $http.get(`/admin/entityProducts/export?action=delete`, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
export const productsService = {
  getProducts,
  getproduct,
  addProduct,
  editProduct,
  getDraftProducts,
  importProducts,
  importProductsUnits,
  exportProducts,
  exportProductsUnits,
  bulkEditProductsImages,
  assignMultipleUnitsOnProduct,
  getUnitsofCertainProduct,
  bulkUpdateProductUnits,
  getProvidersOfCertainProduct,
  UpdateOneProductUnit,
  deleteProductUnit,
  getProvidersAssignedToProductUnit,
  importDeleteProductsSheet,
  exportDeleteProductsSheet,
};
