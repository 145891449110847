import { $http } from "@/api";
import { authHeader } from "@/authHeader";

const lookupsStore = {
  state: {
    lookups: { sections: [], regions: [] },
  },
  getters: {
    getAllLookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    setLookups(state, lookupsPayload) {
      state.lookups = lookupsPayload;
    },
  },
  actions: {
    async fetchLookups({ commit }) {
      let lookups = {};
      let responseHolder;
      const areasLookups = await $http.get("/admin/area", {
        headers: authHeader(),
      });

      // const areasLookups = $http.get("/admin/area", { headers: authHeader() });

      if (
        areasLookups &&
        areasLookups.data &&
        areasLookups.status &&
        areasLookups.status == 200
      ) {
        lookups["areas"] = areasLookups.data.data.areas;
      }
      const trademarkLookups = $http.get("/admin/lookup/trademark", {
        headers: authHeader(),
      });
      const regionsChildrenLookup = $http.get(
        "/admin/lookup/region?exclude=parent",
        {
          headers: authHeader(),
        }
      );

      const manufacturerLookups = $http.get("/admin/lookup/manufacturer", {
        headers: authHeader(),
      });
      const bundlesLookups = $http.get("/admin/creditbundles/active", {
        headers: authHeader(),
      });
      const cashbackIndustriesLookups = $http.get(
        "/admin/cashback/configured-industries",
        {
          headers: authHeader(),
        }
      );
      const categoryLookups = $http.get("/admin/lookup/category", {
        headers: authHeader(),
      });
      const regionLookups = $http.get("/admin/lookup/region", {
        headers: authHeader(),
      });
      const distributerLookups = $http.get("/admin/lookup/distributer", {
        params: { activity: ["provides"] },
        headers: authHeader(),
      });
      // const userGroupLookups = $http.get("/admin/lookup/userGroup", {
      //   headers: authHeader(),
      // });
      const unitLookups = $http.get("/admin/lookup/unit", {
        headers: authHeader(),
      });
      const sectionslookup = $http.get(
        "/admin/sections?filter=%7B%22lookup%22%3A%20true%7D",
        {
          headers: authHeader(),
        }
      );
      const entityTypeLookups = $http.get("/admin/lookup/entity_type", {
        headers: authHeader(),
        params: {
          filter: {
            activity: ["provides", "consumes"],
          },
        },
      });
      const rolesLookup = $http.get("/admin/roles", {
        headers: authHeader(),
      });
      const unitsMainLookup = $http.get("/admin/unit", {
        headers: authHeader(),
      });
      const receiptStatusLookup = $http.get("/admin/lookup/receipts-statuses", {
        headers: authHeader(),
      });
      const transactionStatusLookup = $http.get(
        "/admin/lookup/transactions-statuses",
        {
          headers: authHeader(),
        }
      );
      const industriesStatusLookup = $http.get("/admin/industries", {
        headers: authHeader(),
      });
      responseHolder = await Promise.allSettled([
        [],
        trademarkLookups,
        manufacturerLookups,
        categoryLookups,
        regionLookups,
        distributerLookups,
        regionsChildrenLookup,
        unitLookups,
        sectionslookup,
        entityTypeLookups,
        rolesLookup,
        unitsMainLookup,
        receiptStatusLookup,
        transactionStatusLookup,
        industriesStatusLookup,
        bundlesLookups,
        cashbackIndustriesLookups,
      ])
        .then((data) => {
          lookups["trademarks"] =
            data[1].status == "fulfilled"
              ? data[1].value.data.data.result
              : null;
          lookups["manufacturer"] =
            data[2].status == "fulfilled"
              ? data[2].value.data.data.result
              : null;
          lookups["category"] =
            data[3].status == "fulfilled" ? data[3].value.data.result : null;
          lookups["regions"] =
            data[4].status == "fulfilled" ? data[4].value.data.result : null;
          lookups["distributers"] =
            data[5].status == "fulfilled" ? data[5].value.data.result : null;
          lookups["regionsChildren"] =
            data[6].status == "fulfilled" ? data[6].value.data.result : null;
          lookups["units"] =
            data[7].status == "fulfilled"
              ? data[7].value.data.data.results
              : null;
          lookups["sections"] =
            data[8].status == "fulfilled" ? data[8].value.data.data : null;
          lookups["entity_types"] =
            data[9].status == "fulfilled"
              ? data[9].value.data.data.result
              : null;
          lookups["roles"] =
            data[10].status == "fulfilled" ? data[10].value.data.data : null;
          lookups["unitsMain"] =
            data[11].status == "fulfilled" ? data[11].value.data.data : null;
          lookups["receiptStatuses"] =
            data[12].status == "fulfilled" ? data[12].value.data.data : null;
          lookups["transactionStatuses"] =
            data[13].status == "fulfilled" ? data[13].value.data.data : null;
          lookups["timestamp"] = new Date().getTime();
          lookups["industries"] =
            data[14].status == "fulfilled" ? data[14].value.data : null;
          lookups["bundles"] =
            data[15].status == "fulfilled" ? data[15].value.data.data : null;
          lookups["cashbackIndustries"] =
            data[16].status == "fulfilled" ? data[16].value.data.data : null;
          localStorage.setItem("storedLookups", JSON.stringify(lookups));
          commit("setLookups", lookups);
          return lookups;
        })
        .catch((error) => console.log("error.response :>> ", error.response));
      return responseHolder;
    },
  },
};

export default lookupsStore;
