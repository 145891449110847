import { $http } from "@/api";
import { authHeader } from "@/authHeader";
// import moment from "moment";

async function getRatings({ page = 1, limit = 50, filter }) {
  const { filtersCopy } = processFilters(filter);
  let response = await $http.get("/admin/receipts/rating-validation", {
    headers: authHeader(),
    params: { page, limit, filter: { ...filtersCopy } },
  });
  let fetchedRates = response.data.data.receipts;
  fetchedRates.forEach((singleRate) => {
    singleRate.isFeedbackValid =
      singleRate.is_feedback_valid === 1 ? true : false;
  });
  response.data.data.receipts = fetchedRates;
  return response.data?.data;
}
function processFilters(filters) {
  filters = JSON.parse(JSON.stringify(filters));
  if (filters.hasOwnProperty("creationDate") && filters["creationDate"]) {
    filters = {
      ...filters,
      startDate: filters.creationDate[0],
      endDate: filters.creationDate[1],
      // creation_date_start : moment(filters.creationDate[0]).startOf('day').valueOf(),
      // creation_date_end: moment(filters.creationDate[1]).endOf('day').valueOf(),
    };
  } else {
    filters = {
      ...filters,
      startDate: undefined,
      endDate: undefined,
    };
  }
  if (filters.receiptId) {
    filters.receiptId = +filters.receiptId;
  }
  delete filters?.creationDate;
  let filtersCopy = filters;
  return { filtersCopy };
}
function updateRateValidation(updateData) {
  const id = updateData.id;
  if (updateData.hasOwnProperty("isFeedbackValid")) {
    if (updateData.isFeedbackValid === true) {
      updateData.is_feedback_valid = true;
    } else {
      updateData.is_feedback_valid = false;
    }
  }
  delete updateData.isFeedbackValid;
  delete updateData.id;
  return $http.patch(`/admin/receipts/${id}/rating-validation`, updateData, {
    headers: authHeader(),
  });
}
export const ratingsService = {
  getRatings,
  updateRateValidation,
};
