import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

import { $http } from "@/api";
import { authHeader } from "@/authHeader";

Vue.use(Vuex);

const initialState = () => ({
  lookups: null,
  isRefreshingFlag: false,
});

export const store = new Vuex.Store({
  modules,
  state: {
    numberOfRequestLoading: 0,
    isLoading: false,
    // lookups: { sections: [], regions: [] },
    transactionFilters: { state: "pending" },
    isRTL: false,
    isRefreshingFlag: false,
    currentProviderProfile: null,
  },
  getters: {
    // getAllLookups(state) {
    //   return state.lookups;
    // },
    getTransactionFilters(state) {
      return state.transactionFilters;
    },
    getIsRefreshingFlag(state) {
      return state.isRefreshingFlag;
    },
    getCurrentProviderProfile(state) {
      return state.currentProviderProfile;
    },
  },
  actions: {
    setTransactionFilters({ commit }, payload) {
      commit("setTransactionFilters", payload);
    },
    async setSectionslookup({ commit }) {
      const sections = await $http.get(
        "/admin/sections?filter=%7B%22lookup%22%3A%20true%7D",
        {
          headers: authHeader(),
        }
      );
      let value = sections.data.data;
      commit("setSectionslookup", value);
      return sections;
    },

    reset({ commit }) {
      commit("RESET");
    },
  },
  mutations: {
    // setLookups(state, lookupsPayload) {
    //   // if (state.lookups != null) {
    //   //   state.lookups = Object.assign(state.lookups, lookupsPayload);
    //   // } else {
    //   state.lookups = lookupsPayload;
    //   // }
    // },
    setLoadingState(state, payload) {
      state.isLoading = payload;
    },
    setNumberOfRequests(state, payload) {
      state.numberOfRequestLoading = payload;
    },
    setSectionslookup(state, sectionsPayload) {
      if (state.lookups != null) {
        state.lookups = { ...state.lookups, sections: sectionsPayload };
      } else {
        state.lookups = { sections: sectionsPayload };
      }
    },
    setTransactionFilters(state, payload) {
      state.transactionFilters = payload;
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setIsRTL(state, value) {
      state.isRTL = value;
    },
    setIsRefreshingFlag(state, value) {
      state.isRefreshingFlag = value;
    },
    setCurrentProviderProfile(state, value) {
      state.currentProviderProfile = value;
    },
  },
});
