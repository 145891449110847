import { authHeader } from "../authHeader";

const config = {
  apiUrl: process.env.VUE_APP_BASE_URL || "",
};

export const userService = {
  login,
  logout,
  listAllUsers,
  getUserProfile,
  loginSocial,
};

function login(phone, password) {
  const userDeviceData = localStorage.getItem("userAgentData");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Device: userDeviceData },
    body: JSON.stringify({ phone, password, device: userDeviceData }),
  };

  return fetch(`${config.apiUrl}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // login successful if there's a jwt token in the response
      if (response.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("userToken", response.data.token);
        if (response.data.refreshToken) {
          const refreshToken = response.data.refreshToken;
          localStorage.setItem("refreshToken", refreshToken);
        } else {
          localStorage.setItem("refreshToken", null);
        }
      }

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
function loginSocial(credential) {
  const userDeviceData = localStorage.getItem("userAgentData");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Device: userDeviceData },
    body: JSON.stringify({ ...credential, device: userDeviceData }),
  };

  return fetch(`${config.apiUrl}/auth/loginSocial`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // login successful if there's a jwt token in the response
      if (response.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("userToken", response.data.token);
        if (response.data.refreshToken) {
          const refreshToken = response.data.refreshToken;
          localStorage.setItem("refreshToken", refreshToken);
        } else {
          localStorage.setItem("refreshToken", null);
        }
      }

      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("userToken");
  localStorage.removeItem("userProfile");
  localStorage.removeItem("userData");
  localStorage.removeItem("refreshToken");
}

function getUserProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/user/profile`, requestOptions).then(
    (response) => {
      return response.text().then((text) => {
        const data = text && JSON.parse(text);

        return data;
      });
    }
  );
}

function listAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject("invalid login");
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    if (data.error) {
      return Promise.reject(data.error);
    }

    return data;
  });
}
