import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function getUserGroups(filters, page = 1) {
  return $http
    .get("/admin/userGroups", {
      headers: authHeader(),
      params: { page },
    })
    .then((res) => {
      const {
        data: { result, pages, count, next },
      } = res.data;

      return {
        userGroups: result,
        pagination: {
          totalPages: pages,
          totalResultsCount: count,
          nextPage: next,
        },
      };
    });
}
function getUsers(body) {
  return $http
    .post(
      "/admin/userGroups/users",
      { ...body },
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      console.log(res);

      return {
        userGroups: res.data.users,
      };
    });
}
function createUserGroup(data) {
  return $http.post(
    "/admin/userGroups",
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function editUserGroup(id, data) {
  return $http.put(
    `/admin/userGroups/${id}`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
}
function getSingleUserGroup(id) {
  return $http.get(`/admin/userGroups/${id}`, {
    headers: authHeader(),
  });
}

function deleteUserGroup(id) {
  return $http.delete(
    `/admin/userGroups/${id}`,

    {
      headers: authHeader(),
    }
  );
}
export const userGroupService = {
  getUserGroups,
  createUserGroup,
  editUserGroup,
  getSingleUserGroup,
  deleteUserGroup,
  getUsers,
};
